import React, { Component } from "react";
import apicall from '../../utils/apicall';
import FootballEventComponent from './FootballEventComponent';
import IcehockeyEventComponent from './IcehockeyEventComponent';
import TabsComponent from "../TabsComponent"
import config from "../../config";

export default class TimeLineMainComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reports: [],
            participants: {},
            noReports: false,
            event: null

        }
    }

    isEmptyObject(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    }

    createReport(allEvents) {
        var reports = this.state.reports;
        let reportList = [];
        var sport = this.state.event && this.state.event.sport;
        for (var reportsCount = 0; reportsCount < reports.length; reportsCount++) {
            let report = reports[reportsCount];
            var participants = reports[reportsCount].participants
            var currentScores = {}
            if (!this.isEmptyObject(participants)) {
                if (participants.teamId)
                    participants.team = this.state.participants[participants.teamId].name;
                if (participants.playerId || participants.goalScorerId)
                    participants.player = this.state.participants[participants.playerId || participants.goalScorerId].name;
                if (participants.playerInId)
                    participants.playerIn = this.state.participants[participants.playerInId].name;
                if (participants.playerOutId)
                    participants.playerOut = this.state.participants[participants.playerOutId].name;
                if (participants.assistantId)
                    participants.assist = this.state.participants[participants.assistantId].name;
                if (participants.assistant2Id)
                    participants.assist2 = this.state.participants[participants.assistant2Id].name;
                if (participants.goalScorerId)
                    participants.goalScorer = this.state.participants[participants.goalScorerId].name;
            }
            if (!this.isEmptyObject(report.currentScores)) {
                var i = 0;
                for (let k in report.currentScores) {
                    currentScores[i.toString()] = [this.state.participants[k].name, reports[reportsCount].currentScores[k]];
                    i++;
                }
            }
            if(sport === 'ice-hockey') {
                reportList.push(<IcehockeyEventComponent key={"timelinecomponent" + reportsCount} report={report} participants={participants} currentScores={currentScores} allEvents={allEvents} />);
            } else {
                reportList.push(<FootballEventComponent key={"timelinecomponent" + reportsCount} report={report} participants={participants} currentScores={currentScores} allEvents={allEvents} />);
            }
            
        }
        return reportList;
    }

    componentDidMount() {
        apicall.eventReport(this.props.eventId).then(reports => {
            var noReports = false;
            if (reports.report.length === 0) noReports = true;
            this.setState({
                reports: reports.report,
                event: reports.event,
                participants: reports.participants,
                noReports: noReports
            });
        });
    }

    render() {
        var listOfMenu = ["Alla händelser", "Höjdpunkter"];
        var componentsArray = new Array(listOfMenu.length).fill(null);
        componentsArray[0] = <ul className="report-items-list">{this.createReport(true)}</ul>;
        componentsArray[1] = <ul className="report-items-list">{this.createReport(false)}</ul>;;
        if (this.state.noReports) {
            return (
                <div className="notfound-msg">
                    <h2>{config.notStartedMsg}</h2>
                </div>
            )
        } else {
            return (<TabsComponent components={componentsArray} listofTabs={listOfMenu} theme='timelinebar' generateTabURL={false} />)
        }
    }
}