import React, { Component } from "react";
import apicall from "../utils/apicall";
import { Collapse, Button } from 'reactstrap';
import dayjs from 'dayjs';



export default class DisplayEventInfoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            eventDetails: null,
            collapse: false,
            buttonState: '+ Matchinfo',

        })
    };

    componentDidMount() {
        var eventId = null;
        if (this.props.match) {
            eventId = this.props.match.params.eventId
        }
        eventId = this.props.eventId;
        this.fetchEventDetails(eventId)
    }

    onEntered = () => {
        this.setState({ buttonState: 'Stang' });
    }
    onExited = () => {
        this.setState({ buttonState: '+ Matchinfo' });
    }

    toggle = () => {
        this.setState({
            collapse: !this.state.collapse
        })
    }

    fetchEventDetails = (eventId) => {
        apicall.fetchEvent(eventId).then(
            jsonEvent => {
                var eventDetails = jsonEvent;
                this.setState({
                    eventDetails: eventDetails,
                });
            }
        )
    }

    processEventDetails = () => {
        if (this.state.eventDetails !== null && typeof this.state.eventDetails !== "undefined") {
            var eventDetails = this.state.eventDetails;
            var event = eventDetails.event;
            var participants = eventDetails.participants;
            var tournament = eventDetails.tournament;
            var countries = eventDetails.countries;
            var participantOneId = event.participantIds[0];
            var participantTwoId = event.participantIds[1];
            var participantOneImage = ""
            if (participants[participantOneId] !== "undefined" && participants[participantOneId].images !== "undefined" && participants[participantOneId].images.clubLogo !== "undefined" && participants[participantOneId].images.clubLogo !== null
                && participants[participantOneId].images.clubLogo.url !== "undefined") {
                participantOneImage = participants[participantOneId].images.clubLogo.url + "?rule=clip-56x56";
            }
            var participantTwoImage = "";
            if (participants[participantTwoId] !== "undefined" && participants[participantTwoId].images !== "undefined" && participants[participantTwoId].images.clubLogo !== "undefined" && participants[participantTwoId].images.clubLogo !== null
                && participants[participantTwoId].images.clubLogo.url !== "undefined") {
                participantTwoImage = participants[participantTwoId].images.clubLogo.url + "?rule=clip-56x56";
            }
            return (
                <div className="col-sm-12 event-info-body">
                    <div className="col-sm-12 row text-center ">
                        {this.generateParticipantNameImage(participants[participantOneId].name, participantOneImage)}
                        <div className="col-sm-4 event-score">{(event.results[participantOneId] || {}).runningScore} - {(event.results[participantTwoId] || {}).runningScore}</div>
                        {this.generateParticipantNameImage(participants[participantTwoId].name, participantTwoImage)}
                    </div>
                    <div className="more_info_btn text-center">
                        <Button className="more_info_btn" onClick={this.toggle} style={{ marginBottom: '1rem' }}>{this.state.buttonState}</Button>
                    </div>
                    <Collapse isOpen={this.state.collapse} onEntered={this.onEntered} onExited={this.onExited}>
                        <div className="event-more-info col-sm-12 text-center">
                            {this.generateMatchInfoDiv("Matchstart", "https://static.vglive.no/dist/sportbladet/speltips/event/details/start-time.ca60522c81.svg", dayjs(event.startDate).locale("sv").format('HH:mm:ss'))}
                            {this.generateMatchInfoDiv("Datum", "https://static.vglive.no/dist/sportbladet/speltips/event/details/start-date.d3c133e1dd.svg", dayjs(event.startDate).locale("sv").format('DD-MMMM-YYYY'))}
                            {this.generateMatchInfoDiv("Turnering", "https://cdn-yams.schibsted.com/api/v1/sports-pro/images/3a/3a51232f-dd83-4ae0-8989-dcbe4c537cc2?rule=clip-20x20", tournament.name)}
                            {this.generateMatchInfoDiv("Omgång", "https://static.vglive.no/dist/sportbladet/speltips/event/details/tournament.69b6d5846f.svg", event.tournament.round)}
                            {this.generateMatchInfoDiv("Arena", "https://static.vglive.no/dist/sportbladet/speltips/event/details/venue.d2a28af111.svg", event.details.venue.name)}
                            {this.generateMatchInfoDiv("Publik", "https://static.vglive.no/dist/sportbladet/speltips/event/details/attendance.433b131ec0.svg", event.details.attendance)}
                            {this.generateMatchInfoDiv("Domare", "https://static.vglive.no/dist/sportbladet/speltips/event/details/referee.636c17b0cf.svg", (participants[event.details.refereeId] || {}).name)}
                        </div>
                    </Collapse>
                </div>
            )
        }

    }

    generateParticipantNameImage = (name, imageUrl) => {
        return (
            <div className="col-sm-4">
                <div className="participant-team-image"><img src={imageUrl} /></div>
                <div className="participant-team">{name}</div>
            </div>
        )
    }

    generateMatchInfoDiv = (name, imageUrl, value) => {
        return (
            <div className="col-sm-12 row justify-content-center">
                <div className="col-sm-5 text-right">{name}</div>
                <div className="col-sm-2 text-center "><img className="match_info_img" src={imageUrl} /></div>
                <div className="col-sm-5 text-left">{value}</div>
            </div>
        )
    }

    render() {
        return (
            <div className="col-sm-12">
                {this.processEventDetails()}
            </div>
        )
    }
}