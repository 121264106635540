import React, { Component } from "react";
import EventsListComponent from './EventsListComponent';
export default class CompletedEvents extends Component {
    render() {
        return (
            <EventsListComponent eventType='completed' heading='Resultat'
                className='events-section events-section__completed' malmo={this.props.malmo}
                apicallStatus={this.props.apicallStatus} widgetCity={this.props.widgetCity}/>
        )
    }
}
