import React, { Component } from "react";

export default class LineupmembersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineupsData: null,
            lineupTeam: null,
            imageUrl: null
        }
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    render() {
        return (
            <>
                <div className="jersey-num">
                    {this.state.lineupTeam.jerseyNumber}
                </div>
                <div className="country-flag">
                    <img src={this.state.imageUrl} />
                </div>
                <div className="player-name">
                    {this.state.lineupTeam.name}
                </div>
            </>
        )
    }

}