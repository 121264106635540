
import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Animated } from "react-animated-css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/components/_tabs.scss";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import helpers from '../utils/helpers';
import config from '../config';
import memoizeOne from 'memoize-one';
export default class TabsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            components: null,
            theme: '',
            generateTabURL: true,
            horizontalScroll: false,
            renderLeftTabArrow: false,
            renderRightTabArrow: true,
            triggerAnimation: true,
            onLoadTabScroll: true

        };
        this.tabsHeaderView = React.createRef();
        this.navHeaderLink = React.createRef();
        this.state = {
            ...this.state,
            ...this.props
        };
    }


    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    renderTabHeader = (tabindex) => {
        if (typeof this.state.components !== "undefined" && this.state.components !== null) {
            var theme = this.state.theme;
            var isArray = Array.isArray(this.state.listofTabs);
            var tabsMap = isArray ? this.state.listofTabs : Object.keys(this.state.listofTabs);
            var listofTabsMap = this.state.listofTabsMap;
            let tabHeading = tabsMap.map(
                (tab, index) => {
                    if (typeof tab !== 'undefined') {
                        if (this.state.homepageFilter) {
                            var displayValue = listofTabsMap[tab];
                            if(index === 0){
                                tab = '';
                            }
                        } else {
                            var displayValue = tab;
                        }
                        if (!isArray) tab = this.state.listofTabs[tab].join(",")
                        var navlink = null;
                        if (this.state.generateTabURL) {
                            navlink = <NavLink to={"/" + tab} tag={Link} ref={link => (this.navHeaderLink = link)}
                                className={classnames({ active: tabindex === (index + 1) }, theme + 'navlink')}
                                onClick={() => { this.toggle(index + 1); }}
                            >
                                {displayValue}
                            </NavLink>
                        }
                        else {
                            navlink = <NavLink ref={link => (this.navHeaderLink = link)}
                                className={classnames({ active: tabindex === (index + 1) }, theme + 'navlink')}
                                onClick={() => { this.toggle(index + 1); }}
                            >
                                {displayValue}
                            </NavLink>
                        }
                        if (this.state.listofTabs.length > 7) {
                            var triggerAnimation = this.state.triggerAnimation;
                            if (!triggerAnimation) {
                                setTimeout(() => {
                                    this.setState({
                                        triggerAnimation: true
                                    })
                                }, 10)
                            }
                            return (
                                <NavItem key={"navitem" + index}
                                    className={classnames({ active: tabindex === (index + 1) }, theme + 'navitem mal-tabs')}>
                                    {navlink}
                                </NavItem>
                            )
                        }
                        else {
                            return (<NavItem key={"navitem" + index}
                                className={classnames({ active: tabindex === (index + 1) }, theme + 'navitem mal-tabs')}>
                                {navlink}
                            </NavItem>)
                        }
                    }
                }
            )

            return tabHeading;
        }
    }
    renderTabComponents = (tabindex) => {
        if (typeof this.state.components !== "undefined" && this.state.components !== null) {
            var theme = this.state.theme;
            var isArray = Array.isArray(this.state.listofTabs);
            var tabsMap = isArray ? this.state.listofTabs : Object.keys(this.state.listofTabs);

            let tabsComponent = tabsMap.map(
                (tab, index) => {
                    var component = null;
                    if (typeof tabindex === "undefined" || index === (tabindex - 1)) {
                        component = this.state.components[index];
                    }
                    return (
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true} key={"tabpane" + index + tabindex} >
                            <TabPane key={"tabpane" + index} tabId={index + 1} className={theme + 'tabPane'}>
                                <div className={theme + 'row'}>
                                    <div className={"tab-item " + theme + 'col'}>
                                        {component}
                                    </div>
                                </div>
                            </TabPane>
                        </Animated>)
                }
            )
            return tabsComponent;
        }
    }

    scroll = (step) => {
        if (this.state.horizontalScroll) {
            config.scrollOn = false;
            let tabsView = this.tabsHeaderView.current;
            let navigationTabs = tabsView && tabsView.getElementsByClassName("nav-tabs")[0];
            let displayArrow = helpers.hasScroll(navigationTabs, 'horizontal', step);
            if (config.display.hideLeft === displayArrow) {
                this.setState({
                    renderLeftTabArrow: false
                })
            }
            else if (config.display.hideRight === displayArrow) {
                this.setState({
                    renderRightTabArrow: false
                })
            }
            else if (config.display.displayBoth === displayArrow) {
                this.setState({
                    renderLeftTabArrow: true,
                    renderRightTabArrow: true
                })
            }
            config.scrollOn = true;
            if (step !== 0) {
                setTimeout(() => {
                    this.setState({
                        triggerAnimation: false
                    })
                }, 20);
            }
        }
    }
    scrollTabsToActive = (activeTab) => {
        if (this.state.onLoadTabScroll && this.state.horizontalScroll) {
            this.setState({
                onLoadTabScroll: false
            })
            setTimeout(() => {
                let theme = this.state.theme;
                let tabAnchor = document.getElementsByClassName(theme + "navlink");
                if (typeof tabAnchor !== "undefined" && tabAnchor !== null) {
                    let tabAnchorWidth = 0;
                    for (let i = 0; i < this.state.activeTab - 1; i++) {
                        if(tabAnchor[i]) tabAnchorWidth += tabAnchor[i].offsetWidth;
                    }
                    this.scroll(tabAnchorWidth);
                }
            }, 600);
        }
    }

    scrollTabsToActiveMemoize = memoizeOne(this.scrollTabsToActive);

    render() {
        var tabsHeader = this.renderTabHeader(this.state.activeTab);
        var tabsComponent = this.renderTabComponents(this.state.activeTab);
        if (typeof tabsComponent !== "undefined" && tabsComponent !== null) {
            this.scrollTabsToActiveMemoize(this.state.activeTab);
            var theme = this.state.theme;
            let tabScrollButtonLeft = null;
            let tabScrollButtonRight = null;
            if (this.state.horizontalScroll && this.state.listofTabs.length > 7) {
                if (this.state.renderLeftTabArrow) {
                    tabScrollButtonLeft = <button className="tabsMoreLeft icon icon-chevron left" onClick={() => this.scroll(-150)}>
                    </button>;
                }
                if (this.state.renderRightTabArrow) {
                    tabScrollButtonRight = <button className="tabsMoreRight icon icon-chevron right" onClick={() => this.scroll(150)}>
                    </button>
                }
            }

            return (
                <div>
                    <div className="nav-wrapper" ref={this.tabsHeaderView} onScroll={() => this.scroll(0)}>
                        <Nav tabs className={theme + "nav"}>
                            {tabScrollButtonLeft}
                            {tabsHeader}
                            {tabScrollButtonRight}
                        </Nav>

                    </div>

                    <TabContent activeTab={this.state.activeTab} className={theme + "tabContent"}>
                        {tabsComponent}
                    </TabContent>
                </div >
            );
        }
        else {
            return null;
        }
    }
}