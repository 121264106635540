import React, { Component } from "react";
import helpers from "../../utils/helpers";
import EndEventComponent from './ice-hockey/EndEventComponent';
import StartEventComponent from './ice-hockey/StartEventComponent';
import GoalKeeperOutComponent from "./ice-hockey/GoalKeeperOutComponent";
import ShotShotOnComponent from "./ice-hockey/ShotShotOnComponent";
import PeriodEventComponent from "./ice-hockey/PeriodEventComponent";
import SuspensionMinorComponent from "./ice-hockey/SuspensionMinorComponent";
import ShotShotBlockedComponent from "./ice-hockey/ShotShotBlockedComponent";
import ShotShotOffComponent from "./ice-hockey/ShotShotOffComponent";
import ScoreChangeGoalComponent from "./ice-hockey/ScoreChangeGoalComponent";
import PauseStartComponent from "./ice-hockey/PauseStartComponent";
import TimeoutComponent from "./ice-hockey/TimeoutComponent";

export default class IcehockeyEventComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants,
            currentScores: this.props.currentScores
        }
    }

    getElapsed() {
        let res = [];
        let elapsed = this.state.report.elapsed;
        if (elapsed.minutes !== undefined) {
            res.push(elapsed.minutes);  
        }
        if (elapsed.seconds  !== undefined) {
            res.push(':');
            res.push(helpers.pad(elapsed.seconds, 2));
        }
        return res;
    }

    getEventForDisplay() {
        var report = this.state.report;
        var participants = this.state.participants;
        var currentScores = this.state.currentScores;
        switch (report.type) {
            case "eventEnd": {
                return <EndEventComponent report={report} getElapsed={this.getElapsed()} />;
            }
            case "eventStart": {
                return <StartEventComponent report={report} getElapsed={this.getElapsed()} />;
            }
            case "shot": {
                if (report.subtype === "shotOn" && this.props.allEvents) {
                    return <ShotShotOnComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                } else if (report.subtype === "shotBlocked" && this.props.allEvents) {
                    return <ShotShotBlockedComponent report={report} participants={participants} getElapsed={this.getElapsed()} />; 
                } else if(report.subtype === "shotOff" && this.props.allEvents) {
                    return <ShotShotOffComponent report={report} participants={participants} getElapsed={this.getElapsed()} />; 
                }
            }
            case "suspension": {
                if(report.subtype === "minor" && this.props.allEvents) {
                    return <SuspensionMinorComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "periodStart": {
                if(report.subtype === "period1"){
                    return <PeriodEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Första halvlek" />;
                } else if(report.subtype === "period2") {
                    return <PeriodEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Andra perioden" />;
                }  else if(report.subtype === "period3") {
                    return <PeriodEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Tredje perioden" />;
                }         
            }
            case "goalkeeperActivity": {
                if ((report.subtype === "goalkeeperIn" || report.subtype === "goalkeeperOut") && this.props.allEvents) {
                    return <GoalKeeperOutComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "timeout": {
                if(report.subtype === "timeout" && this.props.allEvents){
                    return <TimeoutComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "scoreChange": {
                if(report.subtype === "goal") {                    
                    return <ScoreChangeGoalComponent report={report} participants={participants} currentScores={currentScores} getElapsed={this.getElapsed()} />;
                }
            }
            case "pauseStart": {
                if(report.subtype === "pauseAfterPeriod1" || report.subtype === "pauseAfterPeriod2") {                    
                    return <PauseStartComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Paus" />;
                }
            }
            
            default: {
                if (this.props.allEvents) {
                    return ""
                }
                else {
                    return null;
                }
            }
        }
    }

    render() {
        return (<>
            {this.getEventForDisplay()}
        </>
        );
    }
}