import React, { Component } from "react";
export default class PageFooter extends Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-column">
                    <div className="footer-info"><p>Användning av automatiska tjänster (robotar, spiders, indexering och liknande) är inte tillåtet.</p>
                        <p><a className="footer-privacy-policy-link" href="https://www.aftonbladet.se/hjalpinfo/vanligafragor/article23620200.ab">Personuppgiftspolicy
                        </a> och  <a className="footer-privacy-policy-link" href="https://www.aftonbladet.se/hjalpinfo/vanligafragor/article20714676.ab">Cookiepolicy</a></p>
                        <a className="footer-privacy-link" href="https://aboutme.schibsted.com/p/schibsted.com/ads">Data och du</a></div></div>
                <div className="footer-column"><div className="footer-staff">
                    <div className="footer-staff-row"><span>Tjänstgörande sportredaktör </span>
                        <span className="footer-content-bolded"><a href="mailto:webbsport@aftonbladet.se">webbsport@aftonbladet.se</a></span></div>
                    <div className="footer-staff-row"><span>Chef, Sportbladet </span>
                        <span className="footer-content-bolded">Patric Hamsch</span></div></div>
                    <div className="footer-copyright">Aftonbladet © <span className="footer-content-bolded">2019</span></div></div>
            </footer>)
    }
}