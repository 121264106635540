import React, { Component } from "react";

export default class EndEventComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report
        }
    }

    render() {
        return (<div className="report-items-list-item">
            <div className="report-item report-item-eventEnd report-item-eventEnd report-item-ice-hockey">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/whistle.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-content">
                        <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>Matchen slut</font>
                        </font>
                    </div>
                </div>
                <div className="report-item-time"></div>
            </div>
        </div>
        );
    }
}