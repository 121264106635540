import React, { Component } from "react";
import dayjs from 'dayjs';
import config from '../config';
import apicall from "../utils/apicall";
import TabsComponent from "./TabsComponent";
import AftonNavbar from './AftonNavbar';
import GamesListComponent from "./GamesListComponent";
import TipsetWidgetComponent from "./TipsetWidgetComponent";
import TodaysMatchesComponent from "./todays_matches/TodaysMatchesComponent";
import TodaysEvents from "./todays_matches/TodaysEvent";
import helpers from '../utils/helpers';
import { Redirect } from "react-router-dom";
import renderHTML from 'react-render-html';
import PageFooter from "./PageFooter";
import MobileNavbar from "./MobileNavbar";
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import _ from "lodash";
export default class SpelDescComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: null,
            imageurl: null,
            deprecatedIntegrations: [],
            activeTab: 1,
            template: null,
            showWidget: false,
            hideTodayMatches: false,
        };
    }

    handleButton = () => {
        this.setState({
            hideTodayMatches: !this.state.hideTodayMatches
        })
    }

    componentDidMount() {
        this.setState({template: this.props.match.params.template})

        config.localforage.getItem('articlesfetchDate', (err, fetchDate) => {
            var duration = dayjs().diff(dayjs(fetchDate));
            var minutes = parseInt(duration / 60000);
            const { articleID, id } = this.props.match.params;
            if (this.props.match.params.articleId !== null && this.props.match.params.articleId !== "null"
                && this.props.match.params.articleId !== 0 && this.props.match.params.articleId !== "0") {
                articleID && apicall.fetchArticleById(articleID).then((article) => {
                    this.setState({
                        article: article,
                        showWidget: article.showWidget
                    });
                })
            }
        });
    }

    markupGenerator = (description, textValue) => {
        var displayText = [];
        var markups = description.text.markup;
        var strongMarkups = markups.filter((e) => (e.type === "style:strong"));
        var currentIndex = 0;
        if (strongMarkups.length > 0) {
            for (let markup of strongMarkups) {
                //Starting normal text
                displayText.push(<>{renderHTML(helpers.replace(textValue.slice(currentIndex, markup.offset)))}</>);
                currentIndex = markup.offset + markup.length;

                //Bold text
                var currentOffset = 0;
                var extraMarkups = markups.filter((e) => (e.type === "link:external" && e.offset >= markup.offset && ((e.offset + e.length) <= (markup.offset + markup.length))));
                if (extraMarkups.length > 0) {
                    //With link
                    extraMarkups = extraMarkups.sort(helpers.compare);

                    for (let extraMarkup of extraMarkups) {
                        if (currentOffset < extraMarkup.offset) {
                            //Starting only bold text                                         
                            displayText.push(<strong>{renderHTML(helpers.replace(textValue.slice(currentOffset, extraMarkup.offset)))}</strong>);
                        }
                        //bold + link text 
                        displayText.push(<a href={extraMarkup.uri} target="_blank" className="abBlueLink"><strong>{renderHTML(helpers.replace(textValue.slice(extraMarkup.offset, (extraMarkup.offset + extraMarkup.length))))}</strong></a>);
                        currentOffset = extraMarkup.offset + extraMarkup.length;
                    }
                    if (currentOffset < (markup.offset + markup.length)) {
                        //Ending only bold text
                        displayText.push(<strong>{renderHTML(helpers.replace(textValue.slice(currentOffset, markup.offset + markup.length)))}</strong>);
                    }
                } else {
                    //Without link
                    displayText.push(<strong>{renderHTML(helpers.replace(textValue.slice(markup.offset, markup.offset + markup.length)))}</strong>);
                }
            }
            //Ending normal text
            displayText.push(<>{renderHTML(helpers.replace(textValue.slice(currentIndex, textValue.length)))}</>);
        } else {
            for (let markup of markups) {
                var markupText = textValue.slice(markup.offset, markup.offset + markup.length);
                displayText.push(<>{textValue.slice(0, markup.offset)}
                    <a classname="speltipsMarkup" href={markup.uri} target="blank">{markupText}</a>
                    {textValue.slice(markup.offset + markup.length)}</>);
            }
        }


        return displayText;
    }

    descriptionGenerator = (descriptionArray) => {
        let ogImageStatus = false;
        let descriptionGenerator = descriptionArray.map(
            (description, index) => {
                var res = [];
                if (index === 0 && description.type !== "image") res.push(this.tagAndTime());
                if (description.type == "breakpoint") {
                    res.push(<br key={index} />);
                } else if ((description.subtype === "lead" || description.subtype === "heading") && description.text.value !== null) {
                    res.push(<p className="speltipdesc" key={"description-" + index}>
                        {renderHTML(helpers.replace(description.text.value))}
                    </p>);
                }
                else if (description.type !== "image" && description.type !== "breakpoint"
                    && typeof description.text !== "undefined") {
                    var textValue = description.text.value;
                    var pointTexts = textValue.match(/\d+\.\s*[A-Za-z\s]+.*/g);
                    if (description.text.hasOwnProperty('markup')) {
                        res.push(<p className="speltipsPara" key={"description-" + index}>{this.markupGenerator(description, textValue)}</p>);
                    } else {
                        res.push(
                            <p className="speltipsPara" key={"description-" + index}>
                                {renderHTML(helpers.replace(description.text.value))}
                            </p>
                        );
                    }

                }
                else if (description.type === "image") {
                    if(!ogImageStatus){
                        res.push(
                            <Helmet>
                                <meta property="og:image" content={description.imageAsset.url} />
                            </Helmet>
                        )  
                        ogImageStatus = true;                      
                    }
                    res.push(<div className="article-image-container" key={"image" + index}>
                        <img className="article-image" src={description.imageAsset.url} alt="" />
                        <div className="article-image-credits">Foto: Place Holder</div>
                    </div>);

                    if (index === 0) res.push(this.tagAndTime());
                    return res;
                }
                return res;
            }
        )
        return descriptionGenerator;
    }

    defaultExpertSuggestion = () => {
        var article = this.state.article;
        if(this.state.template !== 'stryktipset') return "";
        if(article && article.showDefaultUrl) {
            var pathname = this.props.location.pathname;
            var expert_details = article.expert_details || {};
            var firstExpert = expert_details[Object.keys(expert_details)[0]];
            if(firstExpert) {
                var expertName = firstExpert.name;            
                return (
                    <div>
                        <Link className="default-suggestion-link" to={ pathname + (pathname.includes("/showWidget") ? '' : "/showWidget")} >{"Ta rygg på " + expertName + " förslag"}</Link>
                    </div>
                )
            } else {
                return '';
            }
        } else {
            return "";
        }
        
    }

    tagAndTime = () => {
        var articleshown = this.state.article;
        var articleCreatedDate = dayjs(articleshown.article_published).locale("sv").format('ddd DD MMM YYYY');
        return (
            <>
                <div className="articleListData">
                    <div className="date-and-tags">
                        <Link className="article-tags" to="/">
                            {articleshown.tag_names}
                        </Link>
                        <div className="datetime">
                            {articleCreatedDate}
                        </div>
                    </div>
                </div>
                {this.defaultExpertSuggestion()}
            </>
        )
    }

    articleDescGenerator = () => {
        var articleshown = this.state.article;
        var descriptionLead = [];
        var descriptionPara = [];
        var descriptionLeadDivs = null;
        var descriptionArray = articleshown.description;
        var descriptionDiv = this.descriptionGenerator(descriptionArray);

        return (
            <div className="">
                <h1 className="" data-test-id="headline">
                    {articleshown.title}
                </h1>

                {descriptionDiv}
                {/* <p className="speltipsPara">
                    {descriptionPara}
                </p> */}
                <div className="event-section">
                    <GamesListComponent article={articleshown}/>
                </div>
                {this.defaultExpertSuggestion()}
            </div>
        );
    }

    render() {
        if (this.state.article !== null) { 
            var article = this.state.article;
            var template = this.state.template;
            const googleTitle = !_.isEmpty(article.custom_properties.googleTitle) ? article.custom_properties.googleTitle : article.title;
            if(this.props.match.params.title === undefined) {
                let slugifiedTemplate = helpers.slugifyText(template);
                let articleDate = dayjs(article.article_published).locale("sv").format('YYYY-MM-DD');
                let day = dayjs(article.article_published).format('dddd').toLowerCase();
                let slugifiedTitle = helpers.slugifyText(article.title);
                if (slugifiedTemplate === 'stryktipset') {
                    var anchorURL = "/" + slugifiedTemplate + "/" + article.articleid + "/" + articleDate + "-" + day;
                } else if (slugifiedTemplate === 'powerplay') {
                    var anchorURL = "/" + slugifiedTemplate + "/" + article.articleid + "/" + "powerplay-idag-" + articleDate;
                }
                else {
                    var anchorURL = "/" + slugifiedTemplate + "/" + article.articleid + "/" + slugifiedTitle;
                }
                return (<Redirect to={anchorURL} />)
            }           
            var articleID = '';
            var forceShowWidget = '';
            if (this.props.match) {
                articleID = this.props.match.params.articleID;
                forceShowWidget = this.props.match.params.showWidget === "showWidget"
            }
            let listofTabsMap = config.listofTabsMap;
            let listOfMenu = Object.keys(listofTabsMap);
            var articleDescGenerator = this.articleDescGenerator();
            var articleDescArray = new Array(listOfMenu.length).fill(null);
            var activeTab = this.state.activeTab;
            if (typeof template !== "undefined" && template !== null && template !== '') {
                var templateIndex = listOfMenu.findIndex(item => (template.toLowerCase() === item.toLowerCase() || (config.mapTamplates[template] && config.mapTamplates[template].toLowerCase() === item.toLowerCase())));
                if (templateIndex > -1) {
                    articleDescArray[templateIndex] = articleDescGenerator;
                    activeTab = templateIndex + 1;
                }
                else {
                    //Important OtherWise The Menu Entries Will be Added again
                    listOfMenu = helpers.immutablePush(listOfMenu, helpers.capitalizeFirstLetter(template));
                    articleDescArray[listOfMenu.length - 1] = articleDescGenerator;
                    activeTab = listOfMenu.length;
                }
            }
            else {
                articleDescArray.push(articleDescGenerator);
            }
            let showWidget = forceShowWidget || !this.state.hideTodayMatches;
            return (
                <React.Fragment>
                    {helpers.removeMetaTags()}
                     <Helmet>
                        <title>{googleTitle}</title>
                        <meta property="og:title" content={googleTitle} />
                    </Helmet>
                    <AftonNavbar />
                    <div className="ab-theme-gradient-page">
                        <div className="article-main-page">
                            <div className="article">
                                <main className={showWidget ? 'tipset-widget-closed' : 'tipset-widget-open'}>
                                    <TabsComponent components={articleDescArray} listofTabs={listOfMenu}
                                        theme='menubar' activeTab={activeTab}
                                        subactiveTab={activeTab} horizontalScroll={true} listofTabsMap={listofTabsMap}
                                        homepageFilter={true} />
                                </main>
                                <aside>
                                    {(config.mapWidgetTemplate.hasOwnProperty(template) && this.state.showWidget) ?
                                        <>
                                            <TipsetWidgetComponent article={this.state.article}
                                                templateName={config.mapWidgetTemplate[template]} useEventObject={true}
                                                forceShowWidget={forceShowWidget} handleButton={this.handleButton} articleID={articleID} />
                                            {
                                                this.state.hideTodayMatches &&
                                                <div>
                                                    <TodaysEvents filterSports={false} sportName={this.state.sportName} />
                                                </div>
                                            }
                                        </> :
                                        <TodaysEvents filterSports={false} sportName={this.state.sportName} />}
                                </aside>
                            </div>
                        </div>
                    </div>
                    <PageFooter />
                    <MobileNavbar />
                </React.Fragment>)
        }
        else {
            return null;
        }
    }

}