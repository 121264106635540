import React, { Component } from "react";

export default class RedCardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants
        }
    }

    render() {
        return (<div className="report-items-list-item">
            <div className="report-item report-item-card report-item-yellow report-item-football">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/red.svg" />
                    </div>
                </div>

                <div className="report-item-body">
                    <div className="report-item-header">Rött kort!</div>
                    <div className="report-item-content">
                        <div className="report-item-card-player">{this.state.participants.player} - {this.state.participants.team}</div>
                    </div>
                    <div className="report-item-comment">{this.state.report.text}</div>
                </div>
                <div className="report-item-time">{this.props.getElapsed}</div>
            </div>
        </div>
        );
    }
}