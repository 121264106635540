import React, { Component } from "react";
import EventsListComponent from './EventsListComponent';
export default class LiveEvents extends Component {
    render(){
        return(
                    <EventsListComponent eventType='live' heading="Live"
                className="events-section events-section__live" malmo={this.props.malmo} widgetCity={this.props.widgetCity}/>
    
        )
    }
}
