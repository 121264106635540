import React, { Component } from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import HomePageSearchResultComponent from './HomePageSearchResultComponent'
import { faUser, faAlignJustify, faCalendar, faCircle, faGift, faEllipsisV, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class AftonNavbar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <div className="header">
                <Navbar expand="md">
                    <NavbarBrand href="/sportbladet" className="pl-3">
                        <img src="/sportbladet/speltips/logo-sportbladet.svg" height="24" width="56" alt="" />
                    </NavbarBrand>
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="searchboxul main" navbar >
                            <NavItem className="">
                                <HomePageSearchResultComponent/>
                            </NavItem>
                        </Nav>
                        <Nav className="aside" navbar>
                            <NavItem>
                                <NavLink href="#">
                                    <FontAwesomeIcon icon={faAlignJustify} size='2x' />
                                    <span className="pl-2 pr-2 navspantext">Feed </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">
                                    <FontAwesomeIcon icon={faCalendar} size='2x' />
                                    <span className="pl-2 pr-2 navspantext"> När</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">
                                    <FontAwesomeIcon icon={faCircle} size='2x' />
                                    <span className="pl-2 pr-2 navspantext"> Betta</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#">
                                    <FontAwesomeIcon icon={faGift} size='2x' />
                                    <span className="pl-2 pr-2 navspantext"> Bonus</span>
                                </NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    <FontAwesomeIcon icon={faEllipsisV} size='2x' />
                                    <span className="pl-2 pr-2 navspantext"> Mer</span>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem>
                                        Option 1
                      </DropdownItem>
                                    <DropdownItem>
                                        Option 2
                      </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        Reset
                      </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="navbar-nav-user" navbar>
                            <NavItem>
                                <FontAwesomeIcon icon={faUserCircle} size='2x' style={{ color: 'white' }} />
                            </NavItem>
                            <NavbarToggler onClick={this.toggle}>
                                <FontAwesomeIcon color="white" icon={faUser} />
                            </NavbarToggler>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}