import React, { Component } from "react";
import apicall from "../utils/apicall";
import { Collapse, Button } from 'reactstrap';
import dayjs from 'dayjs';
import time from "../images/time.png";
import like from "../images/like.png";
import placeholder from "../images/placeholder.png"
export default class DisplayEvent extends Component {
    constructor(props) {
        super(props);
        this.state = ({
            eventDetails: null,
            collapse: false,
            buttonState: '+ Matchinfo',

        })
    };

    componentWillMount() {
        var eventId = null;
        if (this.props.match) eventId = this.props.match.params.eventId
        eventId = this.props.eventId;
        this.fetchEventDetails(eventId)
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    fetchEventDetails = (eventId) => {
        apicall.fetchEvent(eventId).then(
            jsonEvent => {
                var eventDetails = jsonEvent;
                this.setState({
                    eventDetails: eventDetails,
                });
            }
        )
    }


    processEventDetails = () => {
        if (this.state.eventDetails !== null && typeof this.state.eventDetails !== "undefined") {
            var eventDetails = this.state.eventDetails;
            var event = eventDetails.event;
            var participants = eventDetails.participants;
            var tournament = eventDetails.tournament;
            var countries = eventDetails.countries;
            var participantOneId = event.participantIds[0];
            var participantTwoId = event.participantIds[1];
            return (
                <div className="event-info-body">
                    <div className="col-sm-12 event-info-page">
                        <div className="like">
                            <img src={like}></img>
                        </div>
                    </div>
                    <div className="time col-sm-5 pt-4">
                        <img src={time}></img>
                        <span className="time_start">
                            {dayjs(event.startDate).locale("sv").format('HH:mm')}
                        </span>
                    </div>
                    <div className="location col-sm-5 pt-4">
                        <img src={placeholder}></img>
                        <span className="loc">
                            {event.details.venue.name}
                        </span>
                    </div>
                </div>
            )
        }

    }

    generateParticipantNameImage = (name, imageUrl) => {
        return (
            <div className="col-sm-4">
                <div className="participant-team-image"><img src={imageUrl} /></div>
                <div className="participant-team">{name}</div>
            </div>
        )
    }

    generateMatchInfoDiv = (name, imageUrl, value) => {
        return (
            <div className="col-sm-12 row justify-content-center">
                <div className="col-sm-5 text-right">{name}</div>
                <div className="col-sm-2text-center "><img className="match_info_img" src={imageUrl} /></div>
                <div className="col-sm-5 text-left">{value}</div>
            </div>
        )
    }

    render() {
        return (
            <div className="col-sm-12">
                {this.processEventDetails()}
            </div>
        )
    }
}