import React, { useState, useEffect } from "react";
import EventScoresGenerator from "./EventScoresGenerator";
import apicall from "../../utils/apicall";
import LoaderComponent from "../LoaderComponent";
export default function PastEvents(props) {
  const [homeTeamEvents, setHomeTeamEvents] = useState();
  const [awayTeamEvents, setAwayTeamEvents] = useState();
  const [isLoading, setLoading] = useState(true);
  const fetchData = (participantId, isHomeTeam) =>
    apicall.fetchMalDetails(participantId, 0).then(jsondata => {
      let participantSearchId = participantId;
      var data = {
        ...jsondata,
        participantSearchId
      };
      isHomeTeam ? setHomeTeamEvents(data) : setAwayTeamEvents(data);
      setLoading(false)
    });

  useEffect(() => {
    fetchData(props.participantsData.team1, true);
    fetchData(props.participantsData.team2, false);
  }, []);

  return (
    <div>
      {isLoading && <LoaderComponent/>}
      {homeTeamEvents && (
        <EventScoresGenerator events={homeTeamEvents} showHeader={true} teamId/>
      )}
      {awayTeamEvents && (
        <EventScoresGenerator events={awayTeamEvents} showHeader={true} />
      )}

    </div>
  );
}
