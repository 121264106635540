import React, { Component } from "react";
import apicall from "../utils/apicall";
import LoaderComponent from "./LoaderComponent";
import "../styles/components/_points-table.scss";

export default class PointsTableComponent extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            tournament: null,
            standings: null,
            seasonId: null,
            stageName: null,
            participants: null,
            eventParticipants: null,
            loader: true,
            standingsPresent: false,
            emptyMessage: '',
            eventTeams: null

        })
        this.state = {
            ...this.state,
            ...this.props
        };
    };

    componentWillMount() {
        var eventId = this.props.eventId;
        this.fetchEvent(eventId);
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            if (newprops.eventId !== this.props.eventId) {
                var eventId = this.props.eventId;
                this.fetchEvent(eventId);
            }
        }
    }


    fetchEvent = (eventId) => {
        apicall.fetchEvent(eventId).then(
            jsonEvent => {
                var stageName = jsonEvent.event.tournament.stage;
                var seasonId = jsonEvent.event.tournament.seasonId;
                this.setState({
                    tournament: jsonEvent.event.tournament,
                    stageName: stageName,
                    seasonId: seasonId,
                    eventTeams: jsonEvent.event.participantIds
                }, this.fetchStandings(seasonId, stageName));
            }
        )
    }

    fetchStandings = (seasonId, stageName) => {
        apicall.tournamentStandings(seasonId, stageName).then(
            jsonStandings => {
                if (jsonStandings) {
                    var standings = jsonStandings.standings;
                    var participants = jsonStandings.participants;
                    var eventParticipants = jsonStandings.participantIds;
                    var standingsPresent = true;
                    this.setState({
                        standings: standings,
                        participants: participants,
                        eventParticipants: eventParticipants,
                        standingsPresent: standingsPresent,
                        loader: false
                    })
                } else {
                    this.setState({
                        loader: false,
                        emptyMessage: standingsPresent ? '' : "Ställningar som inte är tillgängliga för denna turnerings säsong",
                    })
                }
            }
        )
    }

    genreatePointsTableDiv = (standings) => {
        var participants = this.state.participants;
        var eventParticipants = this.state.eventParticipants;
        var particiantsData = this.props.particiantsData;
        if (particiantsData !== null && typeof particiantsData !== 'undefined') {
            var teamOneName = particiantsData.team1;
            var teamTwoName = particiantsData.team2;
        }
        let teamStanding = standings.teamStandings.map(
            (teamStanding, index) => {
                var teamId = teamStanding.teamId;
                if (participants.hasOwnProperty(teamId)) {
                    var teamName = participants[teamId].name;
                }
                var teamWins = null;
                if (typeof teamStanding.wins !== 'undefined' && teamStanding.wins !== null) {
                    teamWins = teamStanding.wins;
                } else {
                    teamWins = teamStanding.winsNormaltime + teamStanding.winsOvertime + teamStanding.winsShootout;
                }
                var teamlosses = null;
                if (typeof teamStanding.losses !== 'undefined' && teamStanding.losses !== null) {
                    teamlosses = teamStanding.losses;
                } else {
                    teamlosses = teamStanding.lossesNormaltime + teamStanding.lossesOvertime + teamStanding.lossesShootout;
                }
                return (
                    <div className="points-table-body" key={"standing" + index}>
                        <div className="team-status table-rank">
                            {teamStanding.rank}
                        </div>
                        <div className={'team-status ' + ((this.state.eventTeams.includes(teamStanding.teamId)) ? 'font-bold' : '')}>
                            {teamName}
                        </div>
                        <div className="team-status table-team-played">
                            {teamStanding.played}
                        </div>
                        <div className="team-status table-team-wins">
                            {teamWins}
                        </div>
                        <div className="team-status table-team-draws">
                            {teamStanding.draws}
                        </div>
                        <div className="team-status table-team-loss">
                            {teamlosses}
                        </div>
                        <div className="team-status table-team-goals">
                            {teamStanding.goalsFor}-{teamStanding.goalsAgainst}
                        </div>
                        <div className="team-status table-team-points">
                            {teamStanding.points}
                        </div>
                    </div>
                )
            }
        )
        return teamStanding;
    }

    genereateTableHeading = () => {
        return (
            <div className="points-table-head">
                <div className="points-table-title"></div>
                <div className="points-table-title"></div>
                <div className="points-table-title">S</div>
                <div className="points-table-title">V</div>
                <div className="points-table-title">O</div>
                <div className="points-table-title">F</div>
                <div className="points-table-title">M</div>
                <div className="points-table-title">P</div>
            </div>
        )
    }

    constructTable = (teamStandingsArray) => {
        let teamStandingsDiv = teamStandingsArray.map(
            (teamStandings, index) => {
                return (
                    <div className="points-table" key={index}>
                        {teamStandings.stageName ? (<div className="stage-name">{teamStandings.stageName}</div>) : null}
                        {this.genereateTableHeading()}
                        {this.genreatePointsTableDiv(teamStandings)}
                    </div>
                )
            })
        return teamStandingsDiv;
    }

    render() {
        if (this.state.loader === false) {
            if (this.state.standingsPresent) {
                if (this.state.standings !== null && typeof this.state.standings !== "undefined"
                    && this.state.participants !== null && typeof this.state.participants !== "undefined") {

                    return (
                        this.constructTable(this.state.standings)
                    )
                }
            }
            else {
                return (
                    <div className="empty-message">{this.state.emptyMessage}</div>
                )
            }
        } else return (
            <LoaderComponent ></LoaderComponent>
        )
    }
}