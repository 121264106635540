import React, { Component } from "react";
import SpelTipsComponent from "../SpelTipsComponent";
import config from '../../config';
import apicall from '../../utils/apicall';
import AftonNavbar from '../AftonNavbar';
import PageFooter from '../PageFooter';
import MobileNavbar from "../MobileNavbar"
import { withRouter } from "react-router-dom";

class MalmoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            articleToBeDisplayed: null,
            searchText: '',
            activeTab: 1
        };
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    componentDidMount() {
        console.log(this.state)
        let widgetCity 
        if(this.state.match.path.includes("/malmo"))
            widgetCity = config.widgetCityMap["malmo"]
        else if(this.state.match.path.includes("/uppsala"))
            widgetCity = config.widgetCityMap["uppsala"]
        config.localforage.removeItem("articles").then(() => {
            apicall.fetchMore = true;
            var activeTab = this.state.activeTab;
                apicall.fetchWidgetArticles(widgetCity).then(articles => {
                    this.setState({
                        articles: articles
                    });
                });
        });
    }

    render() {
        return (
            <>
                <AftonNavbar />
                <SpelTipsComponent articles={this.state.articles} activeTab={this.state.activeTab} path={this.props.match.path} 
                malmo={true}/>
                <PageFooter />
                <MobileNavbar />
            </>
        );
    }
}
export default withRouter(MalmoComponent);