import React from 'react'
import author_avatar from "../../images/avatar-author.png";
import helpers from '../../utils/helpers';

export default function Comments(props) {
    const{game, isStartpage, expert} = props;
        if (isStartpage) {
            {
                return (
                    game.hasOwnProperty('game-comment') ?
                        (game.hasOwnProperty('game-comment') ?
                            (<div className="teaser-match-content">
                                <div className="teaser-match-content-editor">
                                    <img src={(expert).hasOwnProperty('image_url') &&
                                        expert.image_url !== "" ? expert.image_url : author_avatar} alt="" />
                                </div>
                                <div className="teaser-match-content-text">
                                    <p>{helpers.formatStr(game["game-comment"])}</p>
                                </div>
                            </div>) :
                            null)
                        :
                        (game.hasOwnProperty('team-a-comment' || 'team-b-comment') ?
                            (<div className="teaser-match-content">
                                <div className="teaser-match-content-editor">
                                    <img src="/sportbladet/speltips/img-steven-lee__300.jpg" alt="" />
                                </div>
                                <div className="teaser-match-content-text">
                                    <p><span style={{ fontWeight: "bold" }}>{game["team-a"] + " - "}</span>{game["team-a-comment"]}</p>
                                    <p><span style={{ fontWeight: "bold" }}>{game["team-b"] + " - "}</span>{game["team-b-comment"]}</p>
                                </div>
                            </div>) :
                            null)
                )
            }
        } else {
            return (
                game.hasOwnProperty('game-comment') || game.hasOwnProperty('team-a-comment')
                    || game.hasOwnProperty('team-b-comment') ?
                    (
                        <div className="teaser-match-content">
                            <div className="teaser-match-content-editor">
                                <img src={expert.hasOwnProperty('image_url') &&
                                    expert.image_url !== "" ? expert.image_url : author_avatar} alt="" />
                            </div>
                            <div className="teaser-match-content-text">
                                {game.hasOwnProperty("game-comment") ?
                                    (<p>{game["game-comment"]}</p>) : null}
                                {game.hasOwnProperty("team-a-comment") ?
                                    (<p><span style={{ fontWeight: "bold" }}>{game["team-a"] + " - "}</span>{game["team-a-comment"]}</p>)
                                    : null}
                                {game.hasOwnProperty("team-b-comment") ?
                                    (<p><span style={{ fontWeight: "bold" }}>{game["team-b"] + " - "}</span>{game["team-b-comment"]}</p>)
                                    : null}
                            </div>
                        </div>
                    ) :
                    null
            )
        }
}
