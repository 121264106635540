import React, { Component } from "react";

export default class GoalKeeperOutComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants
        }
    }

    render() {
        return (<li className="report-items-list-item">
            <div className="report-item report-item-goalkeeperActivity report-item-goalkeeperOut report-item-ice-hockey">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/goalkeeper-hockey.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-header">
                        <div className="report-item-goalkeeper-header">Målvakt lämnar isen</div>
                    </div>
                    <div className="report-item-comment">{this.state.participants.player}, {this.state.participants.team}</div>
                </div>
                <div className="report-item-time">{this.props.getElapsed}</div>
            </div>
        </li>);
    }
}