import React, { Component } from "react";
import TabsComponent from "./TabsComponent";
import { Link } from "react-router-dom";
import config from "../config.js"
import dayjs from 'dayjs';
//This import is required for displaying dates in Swedish
import sv from 'dayjs/locale/sv';
import apicall from '../utils/apicall';
import Waypoint from 'react-waypoint';
import GamesListComponent from "./GamesListComponent";
import spinner from '../images/search.gif';
import TodaysMatchesComponent from "./todays_matches/TodaysMatchesComponent";
import TodaysEvents from "./todays_matches/TodaysEvent";
import slugify from "slugify";
import helpers from "../utils/helpers";
import MalmoEvents from "../component/events/MalmoEvents";
import TipsetWidgetComponent from "./TipsetWidgetComponent";
import _ from "lodash";
export default class SpelTipsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            hideTodayMatches: true,
            showWidget: false,
            widgetCity: props.path.includes("uppsala") ? 'uppsala' : 'malmo'
        }
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    handleButton = () => {
        this.setState({
            hideTodayMatches : !this.state.hideTodayMatches
        })
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    loadMoreArticles = (activeTab) => {
        var activeTab = this.state.activeTab;
        if (this.props.malmo) {
            apicall.fetchWidgetArticles(config.widgetCityMap[this.state.widgetCity]).then(articles => {
                this.setState({
                    articles: articles
                });
            });
        } else {
            if (activeTab === 1) {
                apicall.fetchArticles(this).then(articles => {
                    this.setState({
                        articles: articles
                    });
                });
            }
            else {
                apicall.fetchFilteredArticles(activeTab, this).then(articleObj => {
                    this.setState({
                        articles: articleObj.articles,
                        showWidget: articleObj.showWidget
                    });
                });
            }
        }
    }

    showTag(res, tagContent) {
        if (res) {
            return (
                <div className="article-tag">
                    <h3 className="article-tag-heading">
                        {tagContent}
                    </h3>
                </div>
            )
        } else {
            return "";
        }
    }

    displayAd() {
        return (window.apntag || {}).showTag('apn_ad_slot_1');
    }

    render() {
        let pageName
        if (this.props.path) {
            pageName = helpers.slugifyText(this.props.path)
        }
        const articleLength = this.state.articles.length;
        var templateName = "";
        let activeTab = this.state.activeTab;
        var count = 0;
        let spellArticles = this.state.articles.map(
            (article, index) => {
                var spelMatch = [];
                var articleCreatedDate = dayjs(article.article_created).locale("sv").format('DD MMM YYYY');
                var imagesURL = null;
                if (typeof article.promotion_image !== "undefined" && article.promotion_image !== null) {
                    imagesURL = article.promotion_image;
                }
                var description = "";
                if (!(article.description instanceof Array)) {
                    article.description = article.description.replace(/=>/g, ':');
                    description = JSON.parse(article.description);
                }
                else {
                    description = article.description;
                }
                var shortDescription = "";
                var template = "";
                var isStaginArticle = false;
                for (var i = 0; i < description.length; i++) {
                    if (typeof description[i] !== "undefined" && description[i] !== null) {
                        if (typeof description[i].text !== "undefined" && description[i].text !== null) {
                            if (description[i].type === "text" && i < 2) {
                                shortDescription += description[i].text.value;
                            }
                        }
                        if (i === 0 && imagesURL === null && description[i].type === "image") {
                            imagesURL = description[i].imageAsset.url;
                        } else if (typeof description[i].type !== "undefined" &&
                            description[i].type === "deprecated-integration") {
                                console.log(!_.isEmpty(description[i]) && _.hasIn(description[i],['url','staging_article']) && description[i].url.staging_article === "1")
                            if(config.isProduction && !_.isEmpty(description[i]) && _.hasIn(description[i],['url','staging_article']) && description[i].url.staging_article === "1"){
                                isStaginArticle = true;
                            }
                            if(!isStaginArticle){
                                spelMatch.push(description[i]);
                            var template = description[i].url.template || "";
                            if (template.toLowerCase() === "flexible") {
                                template = description[i].url.tags[0];
                            }
                            templateName = this.showTag(true, template);
                            }
                        }
                    }

                }
                if(!isStaginArticle){
                let slugifiedTemplate = helpers.slugifyText(template);
                let articleDate = dayjs(article.article_published).locale("sv").format('YYYY-MM-DD');
                let day = dayjs(article.article_published).format('dddd').toLowerCase();
                let slugifiedTitle = helpers.slugifyText(article.title);
                if (slugifiedTemplate === 'stryktipset') {
                    var anchorURL = "/" + slugifiedTemplate + "/" + article.articleid + "/" + articleDate + "-" + day;
                } else if (slugifiedTemplate === 'powerplay') {
                    var anchorURL = "/" + slugifiedTemplate + "/" + article.articleid + "/" + "powerplay-idag-" + articleDate;
                }
                else {
                    var anchorURL = "/" + slugifiedTemplate + "/" + article.articleid + "/" + slugifiedTitle;
                }
                var addTriggerToLoad = '';
                //Last But 1 Article Display should trigger new Fetch More API call
                if (articleLength === (index + 1)) {
                    addTriggerToLoad = <> <Waypoint key={index}
                        onEnter={() => this.loadMoreArticles(activeTab)}
                    /><img className={apicall.fetchMore ? "spinner articleList" : "hide"} src={spinner} alt="Loading..."></img></>
                }
                if (spelMatch.length === 0) return addTriggerToLoad;
                var imageDiv = null;
                if (imagesURL !== null && spelMatch.length > 0 && spelMatch[0].url.publish_article_image_startpage_position === '1') {
                    imageDiv = <div className="article-list-des-img">
                        <div className="spel-image">
                            <img className="article-image" src={imagesURL} alt="" />
                        </div>
                    </div>
                } else if (imagesURL !== null && spelMatch.length > 0 && spelMatch[0].url.publish_article_image_startpage_position === '2') {
                    imageDiv = <div className="article-list-des-img-left">
                        <div className="spel-image-left">
                            <img className="article-image" src={imagesURL} alt="" />
                        </div>
                    </div>
                } else if (imagesURL !== null && spelMatch.length > 0 && spelMatch[0].url.publish_article_image_startpage_position === '3') {
                    imageDiv = <div className="article-list-des-img-right">
                        <div className="spel-image-right">
                            <img className="article-image" src={imagesURL} alt="" />
                        </div>
                    </div>
                }
                let publishArticleTitle = false;
                if (spelMatch.length === 0 || spelMatch[0].url.publish_article_title === '1') {
                    publishArticleTitle = true;
                }
                let publishArticleLead = false;
                if (spelMatch.length === 0 || spelMatch[0].url.publish_article_lead === '1') {
                    publishArticleLead = true;
                }

                var ad = '';

                if (count % 5 === 0 && config.displayAds) {
                    {
                        (window.innerWidth <= 767) ? window.apntag.defineTag({
                            invCode: 'se-aftonbladet-wph-sportspel_panorama2',
                            sizes: [320, 320],
                            targetId: 'apn_ad_slot_' + index
                        }) :
                            window.apntag.defineTag({
                                invCode: 'se-aftonbladet-wde-sportspel_helsida1',
                                sizes: [640, 320],
                                targetId: 'apn_ad_slot_' + index
                            })
                    }
                    //start loading tags
                    { window.apntag.loadTags() }

                    ad = <div id={"apn_ad_slot_" + index} key={"apn_ad_slot_" + index}>
                        {window.apntag.anq.push(function () {
                            window.apntag.showTag('apn_ad_slot_' + index);
                        })}
                    </div>
                }

                count++;
                const isMobile =  /iPhone|iPod|Android|webOS|BlackBerry/i.test(navigator.userAgent);
                {console.log("Tab",this.state.activeTab,"mobile:",isMobile)}
                if(this.state.activeTab ==2 && index == 2 && isMobile){
                    return(
                        <TodaysEvents/>
                    )
                }
                if (!publishArticleLead && !publishArticleTitle) {
                    return (<React.Fragment key={"gameslist-" + index}>
                        <GamesListComponent key={"gameslistcomponent" + index} isStartpage={true} article={article} />
                        {ad}
                        {addTriggerToLoad}
                    </React.Fragment>);
                }

                return (
                    <React.Fragment key={"articlelist" + index}>
                        <div className="article-list" key={index}>
                            {templateName}
                            <Link to={anchorURL} className="article-list-desc-link">
                                {imageDiv}
                                <div className="article-list-data">
                                    <h3 className={publishArticleTitle ? "article-list-title" : "hide"}>
                                        {article.title}
                                    </h3>
                                    <p className={publishArticleLead ? "article-list-desc-sub-title" : "hide"}>
                                        {shortDescription}
                                    </p>
                                </div>
                            </Link>
                        </div>
                        {ad}
                        {addTriggerToLoad}
                    </React.Fragment>
                )
                }
            }
        )
        let listOfMenu = [];
        let listofTabsMap = config.listofTabsMap || {};
        let tabsKeys = Object.keys(listofTabsMap);
        for (let tab of tabsKeys) {
            listOfMenu.push(tab);
        }
        var articleListArray = new Array(listOfMenu.length).fill(null);
        articleListArray[activeTab - 1] = spellArticles;
        let path = helpers.slugifyText(this.state.path);
        let headerTitle = config.seoHeaderTag[path];
        return (
            <div className="ab-theme-gradient-page">
                <h1 className="hide">{headerTitle}</h1>
                <div className="articles-list-main-page">
                    <div className="articles-list">
                        <main className="articles-list-main">
                            <div className="article-list-main-box">
                                <TabsComponent components={articleListArray} listofTabs={listOfMenu}
                                    listofTabsMap={listofTabsMap}
                                    theme='menubar' activeTab={activeTab}
                                    subactiveTab={activeTab} horizontalScroll={true} homepageFilter={true} />
                            </div>
                        </main>
                        <aside className="events-sidebar-component">
                            {
                                !this.state.malmo ?
                                    <>
                                        {(config.mapWidgetTemplate.hasOwnProperty(pageName) && this.state.showWidget) ?
                                            <>
                                                <TipsetWidgetComponent article={this.state.article}
                                                    templateName={pageName} useEventObject={true}
                                                    handleButton={this.handleButton} articleID={''} />
                                                {
                                                    !this.state.hideTodayMatches &&
                                                    <div>
                                                        <TodaysEvents filterSports={false} sportName={this.state.sportName} />
                                                    </div>
                                                }
                                            </>
                                            : <TodaysEvents filterSports={false} sportName={this.state.sportName} />}
                                    </>
                                    : <MalmoEvents widgetCity={this.state.widgetCity}/>

                            }
                        </aside>
                    </div>
                </div>
            </div>
        );
    }

}
