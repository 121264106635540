import React, { Component } from "react";
import apicall from '../../utils/apicall';
import dayjs from 'dayjs';
import config from '../../config';
import { Link } from "react-router-dom";
import helpers from "../../utils/helpers";
import teamBlankLogo from "../../images/team-blank-logo.svg";
import _ from "lodash"
export default class TodaysMatchesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: null,
            filterSports: this.props.filterSports,
            sportName: this.props.sportName
        }
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    componentDidMount() {
        var d = new Date();
        var date = dayjs(d).format('YYYY-MM-DD');
        var zoneDiff = d.getTimezoneOffset();
        var game = null;
        //Caching the Response for the Today's Game for 10 minutes
        config.localforage.getItem('todaysGames', (err, todaysGames) => {
            if (todaysGames !== "undefined" && todaysGames !== null) {
                var todaysDiff = dayjs().diff(todaysGames.date);
                var minutes = parseInt(todaysDiff / 60000);
                if (minutes < (1*60)) {
                    this.setState({
                        events: todaysGames
                    })
                } else {
                    apicall.fetchEventsByDate(date, zoneDiff, game).then((JsonEvents) => {
                        var events = JsonEvents;
                        this.setState({
                            events: events
                        })
                    });
                }
            }
            else {
                apicall.fetchEventsByDate(date, zoneDiff, game).then((JsonEvents) => {
                    var events = JsonEvents;
                    this.setState({
                        events: events
                    })
                });
            }
        });
    }

    generateCommentsDiv = (comments) => {
        if (comments !== null && typeof comments !== undefined) {
            var commentsKeys = Object.keys(comments);
            let commentDiv = commentsKeys.map(
                (commentKey, index) => {
                    var comment = comments[commentsKeys[index]];
                    var gameComment = comment.game_comment[0];
                    var expert_name = comment.expert_name;
                    return (gameComment ? (
                        <div className="teaser-match-content" key={"comments" + index}>
                            <div className="teaser-match-content-editor">
                                <img src="/sportbladet/speltips/img-steven-lee__300.jpg" alt="Placeholder" />
                            </div>
                            <div className="teaser-match-content-text">
                                <p>
                                    {this.formatStr(gameComment)}
                                </p>
                            </div>
                        </div>
                    ) : null
                    )
                }
            )
            return commentDiv;
        }
    }

    displayodds = (odds) => {
        if (odds !== null && typeof odds !== undefined) {
            var oddsKeys = Object.keys(odds);
            var oddsArray = [];
            oddsKeys.forEach(function (key) {
                oddsArray.push(odds[key]);
            });
            let oddsDiv = oddsArray.map(
                (odd, oddindex) => {
                    // var clientName = oddsKeys[oddindex];
                    var clientName = 'ODDS';
                    return (
                        <div className="teaser-match-odds" key={"odds" + oddindex}>
                            <div className="column teaser-match-odds-heading">{clientName}</div>
                            <div className="column">
                                <div className="teaser-match-odds-odds">
                                    {/* <a href={helpers.generateBettingUrl(odd[1].outcomeid, odd[1].betting_offer_id, 10)} target="_blank"> */}
                                    {odd[1].value && odd[1].value.toFixed(2)}
                                    {/* </a> */}
                                </div>

                                <div className="teaser-match-odds-odds">
                                    {/* <a href={helpers.generateBettingUrl(odd['X'].outcomeid, odd['X'].betting_offer_id, 10)} target="_blank"> */}
                                    {odd['X'].value && odd['X'].value.toFixed(2)}
                                    {/* </a> */}
                                </div>
                                <div className="teaser-match-odds-odds">
                                    {/* <a href={helpers.generateBettingUrl(odd[2].outcomeid, odd[2].betting_offer_id, 10)} target="_blank"> */}
                                    {odd[2].value && odd[2].value.toFixed(2)}
                                    {/* </a> */}
                                </div>
                            </div>
                        </div>
                    );
                }
            )

            return oddsDiv;
        } else {
            return null;
        }
    }

    generateEvents = (tournamentname, tournamentEvents) => {
        // console.log("tournamentEvents",tournamentEvents)
        const filteredEvents = _.filter(tournamentEvents,{'status':this.props.eventStatus, 'manual':false})
        let eventsDiv = filteredEvents.map(
            (tournamentEvent, index) => {
                var team_info_keys = Object.keys(tournamentEvent.team_info);
                var team1Name = "";
                var team2Name = "";
                var team1Img = "";
                var team2Img = "";
                var team1Id = "";
                var team2Id = "";
                if (team_info_keys.length !== 0) {
                    for (var i = 0; i < team_info_keys.length; i++) {
                        if (tournamentEvent.team_info[team_info_keys[i]].position === "home") {
                            team1Name = tournamentEvent.team_info[team_info_keys[i]].name;
                            team1Img = tournamentEvent.team_info[team_info_keys[i]].logo;
                            team1Img = team1Img ? (team1Img + "?rule=clip-32x32") : teamBlankLogo;
                            team1Id = team_info_keys[i];
                        }
                        if (tournamentEvent.team_info[team_info_keys[i]].position === "away") {
                            team2Name = tournamentEvent.team_info[team_info_keys[i]].name;
                            team2Img = tournamentEvent.team_info[team_info_keys[i]].logo;
                            team2Img = team2Img ? (team2Img + "?rule=clip-32x32") : teamBlankLogo;
                            team2Id = team_info_keys[i];
                        }
                    }
                }

                var oddsKey = Object.keys(tournamentEvent.odds);
                var odds = null;
                var comments = null;
                var eventTime = dayjs(tournamentEvent.start_date).format('HH:mm')
                if (oddsKey.length !== 0) {
                    odds = tournamentEvent.odds;
                }
                var commentsKeys = Object.keys(tournamentEvent.comments);
                if (commentsKeys.length !== 0) {
                    comments = tournamentEvent.comments;
                }
                var articleId = null;
                var article_info = tournamentEvent.article_info;
                if (article_info.hasOwnProperty('articleid')) {
                    articleId = article_info.articleid;
                }
                var sportName = tournamentEvent.sport;
                if (tournamentEvent.results[team1Id] && tournamentEvent.results[team2Id]) {
                    var team1Score = tournamentEvent.results[team1Id].hasOwnProperty("runningScore") ?
                        tournamentEvent.results[team1Id].runningScore : "";
                    var team2score = tournamentEvent.results[team2Id].hasOwnProperty("runningScore") ?
                        tournamentEvent.results[team2Id].runningScore : "";
                }
                let eventDate = dayjs(tournamentEvent.start_date).format('YYYY-MM-DD')
                let slugifiedTeamNames = helpers.slugifyText(team1Name) + "-vs-" + helpers.slugifyText(team2Name);
                let slugifiedTournamentName = helpers.slugifyText(tournamentname);
                if (articleId) {
                    var eventURL = "/" + tournamentEvent.eventid + "/" + articleId + "/" + config.mapSportName[sportName] + "/" +
                        slugifiedTournamentName + "/" + slugifiedTeamNames + "/" + eventDate;
                } else {
                    var eventURL = "/" + tournamentEvent.eventid + "/" + config.mapSportName[sportName] + "/" +
                        slugifiedTournamentName + "/" + slugifiedTeamNames + "/" + eventDate;
                }

                return (
                    <Link to={eventURL}
                        className={"teaser-match " + ((index > 0) ? 'reduce-margin' : '')} key={"event" + index}
                        onClick={() => helpers.scrollTo(document.documentElement, 0, 300, 'vertical')}>
                        <h3 className={"heading " + ((index > 0) ? 'hide' : '')}>
                            {tournamentname}
                        </h3>
                        <div className="teaser-match-heading">
                            <div className="team team-left">
                                <div className="team-name" title={team1Name}>{team1Name}</div>
                                <img src={team1Img} className="team-logo team-logo__right" alt="" />
                            </div>
                            {/* <div className="time">{eventTime}</div> */}
                            {tournamentEvent.status === 'notStarted' ?
                                (<div className="time">{eventTime}</div>) :
                                (<div className="time">{team1Score} - {team2score}</div>)
                            }
                            <div className="team">
                                <img src={team2Img} className="team-logo team-logo team-logo__left" alt="" />
                                <div className="team-name" title={team2Name}>{team2Name}</div>
                            </div>
                            <div className="teaser-match-link icon icon-chevron right"> </div>
                        </div>
                        { tournamentEvent.status === 'notStarted' ? this.displayodds(odds) : ''}
                        {this.generateCommentsDiv(comments)}
                    </Link>
                );
            }
        )
        return eventsDiv;
    }

    formatStr = (str) => {
        if (str !== null) {
            return str.substring(0, 130) + '...';
        } else {
            return null;
        }
    }

    generateTournamentEvents = (events) => {
        var eventsData = events;
        if (this.state.filterSports) {
            let sportName = this.state.sportName;
            var sportNameEnglish = config.mapSportEnglish[sportName];
            var sport = events[sportNameEnglish];
            if (typeof sport === "undefined" || sport === null) {
                return null;
            }
            var tournamentKeys = Object.keys(sport);
            var tournamentDiv = tournamentKeys.map(
                (tournamentKey, index) => {
                    var tournamentname = sport[tournamentKey].name;
                    var tournamentEvents = sport[tournamentKey].events;
                    return (
                        this.generateEvents(tournamentname, tournamentEvents)
                    )
                }
            )
            return tournamentDiv;
        } else {
            var eventsDataKeys = Object.keys(eventsData);
            var eventsDataDiv = eventsDataKeys.map(
                (eventsDataKey, index) => {
                    var sport = eventsData[eventsDataKey];
                    if (typeof sport === "undefined" || sport === null) {
                        return null;
                    }
                    var tournamentKeys = Object.keys(sport);
                    var tournamentDiv = tournamentKeys.map(
                        (tournamentKey, index) => {
                            var tournamentname = sport[tournamentKey].name;
                            var tournamentEvents = sport[tournamentKey].events;
                            return (
                                this.generateEvents(tournamentname, tournamentEvents)
                            )
                        }
                    )
                    return tournamentDiv;
                }
            )
            return eventsDataDiv;
        }

    }

    render() {
        if (this.state.events !== null && typeof this.state.events !== undefined) {
            var events = this.state.events;
            var date = dayjs(new Date()).locale("sv").format('DD MMM YYYY');
            return (
                <div className="sidebar-component" >
                    {
                        this.props.eventStatus == config.FINISHED ? 
                        <h2 style={{marginTop : '15px'}} className="today-date">Dagens matcher - Resultat</h2> : 
                        <h2 style={{marginTop : '15px'}} className="today-date">Dagens matcher - {date}</h2>
                    }
                    {this.generateTournamentEvents(events)}
                </div>
            );
        } else {
            return null;
        }
    }
}
