import React, { Component } from 'react';
import './styles/_main.scss';
import dayjs from 'dayjs';
import config from "./config";
import Homepage from './component/Homepage';
import SpelDescComponent from "./component/SpelDescComponent";
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
import SearchEventComponent from './component/SearchEventComponent';
import helpers from "./utils/helpers";
import apicall from './utils/apicall';
import Meta from './component/Meta';
import RedirectComponent from './component/RedirectComponent';
import WidgetComponent from './component/events/WidgetComponent';
import MalmoComponent from './component/events/MalmoComponent';
const RouteWithMeta = ({ component: Component, ...props }) => {
  return (
    <Switch>
      <Redirect strict exact from={props.path + '/'} to={props.path} />
      <Route
        {...props}
        render={routeProps => (
          <React.Fragment>
            <Meta {...props} />
            <Component {...routeProps} {...props} />
          </React.Fragment>
        )}
      />
    </Switch>
  )
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listofTabs: config.listofTabs,
      listofTabsMap: config.listofTabsMap
    }
  }

  componentDidMount() {
    var supportsTouch = helpers.isTouchDevice();
    if (supportsTouch) {
      document.getElementsByTagName("body")[0].classList.add("abTouch")
    }
    else {
      document.getElementsByTagName("body")[0].classList.add("abNoTouch")
    }
    config.localforage.getItem('tabsfetchDate', (err, fetchDate) => {
      var duration = dayjs().diff(dayjs(fetchDate));
      var minutes = parseInt(duration / 60000);
      //Cache The Tabs List for 5 minutes
      if (minutes < (10*60)) {
        config.localforage.getItem('tabsMap', (err, tabsMap) => {
          config.listofTabsMap = tabsMap;
          this.setState({
            listofTabsMap: tabsMap
          })
        });
      }
      else {
        apicall.getTabs().then((tabs) => {
          config.listOfMenu = tabs;
          config.listofTabsMap = helpers.slugify(tabs);
          config.localforage.setItem('tabsfetchDate', new Date(), function (err) {
          })
          config.localforage.setItem('tabsMap', config.listofTabsMap, function (err) {

          })
          this.setState({
            listofTabsMap: config.listofTabsMap
          })
        });
      }
    });
  }

  render() {
    var tabMenuRoutes = null;
    var listofTabsMap = this.state.listofTabsMap || {};
    var tabsMapKeys = Object.keys(listofTabsMap);
    if (typeof tabsMapKeys !== "undefined") {
      tabMenuRoutes = tabsMapKeys.map((tab, index) => {
        return (
          <RouteWithMeta key={"path" + tab} exact strict path={"/" + tab} component={() => <Homepage activeTab={index + 1} listofTabsMap={listofTabsMap}/>} />
        )
      })
      return (
        <BrowserRouter basename={config.baseUrlName}>
          <RedirectComponent>
            <Switch>
              <RouteWithMeta exact path="/" component={() => <Homepage activeTab={1} listofTabsMap={listofTabsMap} />} />
              {tabMenuRoutes}
              {/* <Redirect strict exact from={'/speltips'} to={'/'} /> */}
              {/* <Route path="/malservice" component={SchedulesComponent} /> */}
              <Route path="/widget-events" component={WidgetComponent}/> 
              <Route path="/widget-events-uppsala" component={WidgetComponent}/> 
              <Route path="/malmo" component={MalmoComponent}/>
              <Route path="/uppsala" component={MalmoComponent}/>
              <Route exact path="/event/:eventId/:articleId/:sportName/:gameCount/:template" component={SearchEventComponent} />
              <Route path="/:eventId/:articleId/:sportName/:tournament/:teams/:eventDate" component={SearchEventComponent} />
              <RouteWithMeta exact path="/:eventId/:sportName/:tournament/:teams/:eventDate" component={SearchEventComponent} />            
              <RouteWithMeta path="/:template/:articleID/:title?/:showWidget?" component={SpelDescComponent} />
            </Switch>
          </RedirectComponent>
        </BrowserRouter>
      );
    }
    else {
      return null;
    }

  }
}

export default App;
