import React, { Component } from "react";
import config from '../../config';
import apicall from '../../utils/apicall';
import dayjs from 'dayjs'
import teamBlankLogo from "../../images/team-blank-logo.svg";
import "../../styles/components/_past-events.scss";
export default class TournamentPastScore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayIndividualScores: false,
            participantId: 0,
            opponentId: 0,
            participantEventsArray: [],
            oppositionEventsArray: [],
            oppositionTournamentsArray: [],
            tournamentsArray: [],
            previousMatches: {}
        }
        this.state = {
            ...this.state,
            ...this.props
        }
    }

    componentWillMount() {
        this.fetchParticipants();
    }
    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            let participantId = newprops.participantsData.team1;
            let opponentId = newprops.participantsData.team2;
            if (this.props.participantsData.team1 !== participantId || opponentId !== this.props.participantsData.team2 ||
                newprops.displayIndividualScores !== this.state.displayIndividualScores) {
                this.setState({
                    ...this.state,
                    ...newprops
                }, this.fetchParticipants);
            }
        }
    }

    fetchParticipants = () => {
        if (typeof this.state.participantsData !== "undefined" && this.state.participantsData !== null
        ) {
            var participantId = this.state.participantsData.team1;
            var opponentId = this.state.participantsData.team2;
            var displayIndividualScores = this.state.displayIndividualScores;
            if (displayIndividualScores) {
                apicall.fetchMalDetails(participantId, 0).then(
                    jsondata => {
                        let participantSearchId = participantId;
                        var data = {
                            ...jsondata,
                            participantSearchId
                        };
                        this.processMalDetails(data, true);
                    }
                );
                apicall.fetchMalDetails(opponentId, 0).then(
                    jsondata => {
                        let participantSearchId = opponentId;
                        var data = {
                            ...jsondata,
                            participantSearchId
                        };
                        this.processMalDetails(data, false);
                    }
                );

            }
            else {
                //Compare the same Team Matches
                if (this.state.previousMatches.events.length > 0) {
                    this.processMalDetails(this.state.previousMatches, true)
                } else {
                    apicall.fetchMalDetails(participantId, opponentId).then(
                        jsondata => {
                            this.processMalDetails(jsondata, true);
                        }
                    );
                }
            }
        }
    }

    processMalDetails = (jsondata, addOppositionArray) => {
        var participantEventsArray = this.state.participantEventsArray;
        participantEventsArray = jsondata.events.reverse();
        var tournamentsArray = this.state.tournamentsArray;
        var participantSearchId = jsondata.participantSearchId;
        tournamentsArray = jsondata.tournaments;
        var matchParticipants = {
            ...config.particpantsObj,
            ...jsondata.participants
        };
        config.particpantsObj = matchParticipants;
        this.setState({
            matchParticipants: matchParticipants
        });
        if (addOppositionArray) {
            tournamentsArray["participantSearchId"] = participantSearchId;
            this.setState({
                tournamentsArray: tournamentsArray,
                participantEventsArray: participantEventsArray,
            });
        }
        else {
            tournamentsArray["oppositionSearchId"] = participantSearchId;
            this.setState({
                oppositionEventsArray: participantEventsArray,
                oppositionTournamentsArray: tournamentsArray
            });
        }
    }


    eventScoreGenerator = (pastEventsArray, tournaments) => {
        let eventScoreGenerator = pastEventsArray.map(
            (pastEvent, index) => {
                if (pastEvent.results !== null && pastEvent.results !== "undefined") {
                    var participants = pastEvent.participantIds;
                    var results = pastEvent.results;
                    var eventDate = dayjs(pastEvent.startDate).locale("sv").format('DD MMMM YYYY');
                    if (tournaments !== null && tournaments !== "undefined") {
                        var tournamentName = tournaments[pastEvent.tournament.id].name;
                    }
                    var displayIndividualScores = this.state.displayIndividualScores;
                    var matchParticipants = this.state.matchParticipants;

                    var teamOneLogoObject = matchParticipants[participants[0]].images;
                    var teamOneLogo = teamOneLogoObject.hasOwnProperty('clubLogo') ? (teamOneLogoObject.clubLogo.url + "?rule=clip-32x32") : teamBlankLogo;
                    var teamTwoLogoObject = matchParticipants[participants[1]].images;
                    var teamTwoLogo = teamTwoLogoObject.hasOwnProperty('clubLogo') ? (teamTwoLogoObject.clubLogo.url + "?rule=clip-32x32") : teamBlankLogo;

                    var text = null;
                    if (index === 0) {
                        if (displayIndividualScores) {
                            if ((typeof tournaments.participantSearchId !== "undefined" &&
                                tournaments.participantSearchId !== null)) {
                                text = matchParticipants[tournaments.participantSearchId].name;
                            }
                            else if (typeof tournaments.oppositionSearchId !== "undefined" &&
                                tournaments.oppositionSearchId !== null) {
                                text = matchParticipants[tournaments.oppositionSearchId].name;
                            }

                        }
                        else {
                            text = config.listofMalTableTabs[0];
                        }
                    }
                    var eventsHeader = null;
                    if (typeof text !== "undefined" && text !== null) {
                        eventsHeader = <div className="match-stats-heading">
                            {text}
                        </div>;
                    }
                    if (typeof matchParticipants[participants[0]] !== "undefined" && matchParticipants[participants[0]] !== null
                        && typeof matchParticipants[participants[1]] !== "undefined" && matchParticipants[participants[1]] !== null) {
                        return (
                            <div className="mal-past-events" key={"eventscore" + index}>
                                {eventsHeader}
                                <div className="match-stats-content">
                                    <div className="match-stats-info">
                                        <div className="league">
                                            <span className="icon icon-dot icon-dot__blue"></span>
                                            {tournamentName}
                                        </div>
                                        <div className="time">
                                            {eventDate}
                                        </div>
                                    </div>
                                </div>
                                <div className="teaser-match-heading">
                                    <div className={"team team-left" + ((results[participants[0]].runningScore > results[participants[1]].runningScore) ? ' font-weight-bold' : '')}>
                                        <div className="team-name">{matchParticipants[participants[0]].name}</div>
                                        <img src={teamOneLogo} className="team-logo team-logo__right" alt=""></img>
                                    </div>
                                    <div className="score">
                                        {results[participants[0]].runningScore} - {results[participants[1]].runningScore}
                                    </div>
                                    <div className={"team" + ((results[participants[0]].runningScore < results[participants[1]].runningScore) ? ' font-weight-bold' : '')}>
                                        <img src={teamTwoLogo} className="team-logo team-logo__left" alt=""></img>
                                        <div className="team-name">{matchParticipants[participants[1]].name}</div>
                                    </div>
                                </div>
                            </div >
                        )
                    }
                    else {
                        return null;
                    }
                }
                else {
                    return null;
                }
            }
        )
        return eventScoreGenerator;
    }

    render() {
        var eventScoreGenerator = null;
        var oppositionScoreGenerator = null;
        var displayIndividualScores = this.state.displayIndividualScores;
        if (displayIndividualScores) {
            oppositionScoreGenerator = this.eventScoreGenerator(this.state.oppositionEventsArray, this.state.oppositionTournamentsArray);
        }
        eventScoreGenerator = this.eventScoreGenerator(this.state.participantEventsArray, this.state.tournamentsArray);
        return (
            <div>
                {eventScoreGenerator}
                {oppositionScoreGenerator}
            </div>
        )
    }

}