import React, { Component } from "react";
import config from "../../../config";
export default class TimelineComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            ...this.props
        }
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    render() {
        var timeLineImages = config.timeLineImagesObj;
        var reports = this.state.report;
        var reportImage = timeLineImages[reports.type];
        var imageDiv = null;

        if (typeof reportImage !== "undefined" && reportImage !== null) {
            imageDiv = <img className="report-icon" src={"https://assets.vglive.no/icons/report-items/" + reportImage} />
        }
        var description = this.state.text;
        
        if (typeof description === "undefined" || description === null) {
            description = reports.text;
        }
        if (typeof description === "undefined" || description === null) {
            return null;
        }
        
        return (<div className="report-items-list-item">
            <div className={"report-item report-item-eventEnd report-item-" + reports.type + " report-item-football"}>
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        {imageDiv}
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-content">
                        <font style={{ verticalAlign: 'inherit' }}>
                            <font style={{ verticalAlign: 'inherit' }}>{description}</font>
                        </font>
                    </div>
                </div>
                <div className="report-item-time">
                    {this.state.getElapsed}
                </div>
            </div>
        </div>
        );
    }

}