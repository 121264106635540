import React from 'react'
import TodaysMatches from './TodaysMatchesComponent';
import config from '../../config';
export default function TodaysMatchesLive(props) {
    return (
        <>
          <TodaysMatches eventStatus={config.NOT_STARTED} filterSports={props.filterSports} sportName={props.sportName}/>  
        </>
    )
}
