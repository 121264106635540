import React, { Component } from "react";

export default class SubstitutionEventComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants

        }
    }

    render() {
        return (<div className="report-items-list-item">
            <div className="report-item report-item-substitution report-item-substitution report-item-football">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/substitution.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-header">
                        <div className="report-item-subst-header">Spelarbyte - {this.state.participants.team}</div>
                    </div>
                    <div className="report-item-content">
                        <div className="report-item-subst-content">
                            <div className="report-item-subst-players">
                                <div className="report-item-subst-player-in">
                                    <span className="report-item-subst-label">In: </span>
                                    <span>{this.state.participants.playerIn}</span>
                                </div>
                                <div className="report-item-subst-player-out">
                                    <span className="report-item-subst-label">Ut: </span>
                                    <span>{this.state.participants.playerOut}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-item-comment">{this.state.report.text}</div>
                </div>
                <div className="report-item-time">{this.props.getElapsed}</div>
            </div>
        </div>
        );
    }
}