import React, { Component } from "react";
import { Input } from 'reactstrap';
import apicall from '../utils/apicall';
import spinner from '../images/search.gif';
import { Link } from "react-router-dom";
import renderHTML from 'react-render-html';
import helpers from '../utils/helpers';
import dayjs from 'dayjs';
import config from "../config";
export default class HomePageSearchResultComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            past_events: [],
            upcoming_events: [],
            articles: [],
            displayResult: false,
            isLoading: false,
        };
    }

    onSeachTextChange(searchText) {
        apicall.searchArticlesAndEvents(searchText).then(searchResult => {
            var displayResult = false;
            if (searchResult.articles.length > 0 || searchResult.past_events.length > 0 || searchResult.upcoming_events.length > 0) {
                displayResult = true
            }
            this.setState({
                articles: searchResult.articles,
                searchText: searchText,
                past_events: searchResult.past_events,
                upcoming_events: searchResult.upcoming_events,
                displayResult: displayResult,
                isLoading: false
            });
        });
    }

    //                <RouteWithMeta path="/:eventId/:articleId/:sportName/:tournament/:teams/:eventDate" component={SearchEventComponent} />

    getDisplay() {
        var res = []

        if (this.state.upcoming_events.length > 0) {
            res.push(<div className="select-search-box__group-header">Kommande</div>);
            for (let event of this.state.upcoming_events) {

                let eventDate = dayjs(event.start_date).format('YYYY-MM-DD');
                let team_names = event.team_names;
                let teams = helpers.slugifyText(team_names[0]) + "-vs-" + helpers.slugifyText(team_names[1]);
                let eventUrl = "";
                if (event.articleid) {
                    eventUrl = "/" + event.eventid + "/" + event.articleid + "/" + config.mapSportName[event.sport] + "/" + helpers.slugifyText(event.tournament_name) + "/" +
                        teams + "/" + eventDate
                }
                else {
                    eventUrl = "/" + event.eventid + "/" + config.mapSportName[event.sport] + "/" + helpers.slugifyText(event.tournament_name) + "/" +
                        teams + "/" + eventDate
                }
                res.push(
                    <li className="search-item">
                        <Link to={eventUrl}>{renderHTML(helpers.boldString(event.name, this.state.searchText))}
                        </Link></li>
                )
            }
        }

        if (this.state.past_events.length > 0) {
            res.push(<div className="select-search-box__group-header">Tidigare</div>);
            for (let event of this.state.past_events) {
                let eventDate = dayjs(event.start_date).format('YYYY-MM-DD');
                let team_names = event.team_names;
                let teams = helpers.slugifyText(team_names[0]) + "-vs-" + helpers.slugifyText(team_names[1]);
                if (event.articleid) {
                    var eventUrl = "/" + event.eventid + "/" + event.articleid + "/" + config.mapSportName[event.sport] + "/" + helpers.slugifyText(event.tournament_name) + "/" +
                        teams + "/" + eventDate
                } else {
                    var eventUrl = "/" + event.eventid + "/" + config.mapSportName[event.sport] + "/" + helpers.slugifyText(event.tournament_name) + "/" +
                        teams + "/" + eventDate
                }
                res.push(
                    <li className="search-item">
                        <Link to={eventUrl}>{renderHTML(helpers.boldString(event.name, this.state.searchText))}
                        </Link></li>
                )
            }
        }
        if (this.state.articles.length > 0) {
            res.push(<div className="select-search-box__group-header">Artiklar</div>);
            for (let article of this.state.articles) {
                let template = helpers.slugifyText(article.template_names);
                let slugifiedTitle = helpers.slugifyText(article.title);
                res.push(<li className="search-item">
                    <Link to={"/" + template + "/" + article.articleid + '/' + slugifiedTitle} >
                        {this.formatStr(article.title)}
                    </Link></li>)
            }
        }

        return res;
    }

    formatStr(str) {
        if (str.length > 53) {
            return str.substring(0, 50) + '...';
        }
        return str;
    }

    onEnterPress = (e) => {
        var searchText = e.target.value;
        if (searchText.length >= 3) {
            this.setState({
                searchText: searchText,
                isLoading: true
            });
            this.onSeachTextChange(e.target.value);
        }
    }

    searchSpinner = () => {
        return (
            <img className="search-loader-img" src={spinner} alt="Loading..."></img>
        )
    }


    render() {
        return (<div>
            {this.state.isLoading ? this.searchSpinner() : ''}
            <Input className="sb-search" type="search" name="search" id="searchArticle" placeholder="Sök"
                onKeyUp={this.onEnterPress}
            />
            <div className={"select-search-box__select " + (this.state.displayResult ? "select-search-box__select--display" : "")}>{this.getDisplay()}</div>
        </div>
        )
    }
}