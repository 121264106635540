import React, { Component } from "react";
import SpelTipsComponent from "./SpelTipsComponent";
import config from '../config';
import apicall from '../utils/apicall';
import AftonNavbar from './AftonNavbar';
import PageFooter from './PageFooter';
import MobileNavbar from "./MobileNavbar"
import { withRouter } from "react-router-dom";

class Homepage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles: [],
            articleToBeDisplayed: null,
            searchText: '',
            activeTab: 1,
            showWidget: false
        };
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            this.setState({
                ...this.state,
                ...newprops
            });
        }
    }

    componentDidMount() {
        if(Object.keys(this.state.listofTabsMap).length === 0) return;
        config.localforage.removeItem("articles").then(() => {
            apicall.fetchMore = true;
            var activeTab = this.state.activeTab;
            if (activeTab === 1) {
                apicall.fetchArticles().then(articles => {
                    this.setState({
                        articles: articles
                    });
                });
            }
            else {
                apicall.fetchFilteredArticles(activeTab).then(articleObj => {
                    this.setState({
                        articles: articleObj.articles,
                        showWidget: articleObj.showWidget
                    });
                });
            }
        });
    }

    render() {
        return (
            <>
                <AftonNavbar />
                <SpelTipsComponent articles={this.state.articles} activeTab={this.state.activeTab} path={this.props.match.path} showWidget={this.state.showWidget} />
                <PageFooter />
                <MobileNavbar />
            </>
        );
    }
}
export default withRouter(Homepage);