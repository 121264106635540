import React, { Component } from "react";

export default class PauseStartComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants,
            txt: this.props.txt
        }
    }

    render() {
        return (<li className="report-items-list-item list-item-pause">
            <div className="report-item report-item-periodChange report-item-pauseAfterPeriod1 report-item-ice-hockey report-item-periodChange-pause">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/whistle.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-content">{this.state.txt}</div>
                </div>
                <div className="report-item-time"></div>
            </div>
        </li>);
    }
}