import React, { Component } from "react";
import spinner from "../images/search.gif"

export default class LoaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = ({

        })
    };

    render() {
        return (
            <div className="loader-img-div">
                <img className="loader-img" src={spinner}></img>
            </div>
        )
    }
}