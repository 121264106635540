import React, { Component } from "react";
import LineupmembersComponent from "./LineupmembersComponent";
import TabsComponent from "./TabsComponent";
import LoaderComponent from "./LoaderComponent";
import "../styles/components/_lineups.scss"
import teamBlankLogo from "../images/team-blank-logo.svg"

export default class LineupComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lineupsData: null,
            lineupArray: null,
            countries: null,
            teamOneName: null,
            teamTwoName: null,
            generateTabURL: true,
            loader: true,
            nolineups: false,
            statusCode: null
        }
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentDidMount() {
        var eventId = this.props.eventId;
        var lineupsData = this.props.lineupsData;
        var countries = lineupsData.countries;
        this.setState({
            lineupsData: lineupsData,
            countries: countries,
            loader: false
        }, this.linupsGenerator(lineupsData))
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            if (newprops.eventId !== this.props.eventId) {
                var eventId = this.props.eventId;
                var lineupsData = this.props.lineupsData;
                var countries = lineupsData.countries;
                this.setState({
                    lineupsData: lineupsData,
                    countries: countries,
                    loader: false,
                    eventId: eventId
                }, this.linupsGenerator(lineupsData));
            }
        }
    }

    linupsGenerator = (lineupsData) => {
        var lineups = lineupsData.lineups;
        var teams = Object.keys(lineups);
        var lineupArray = [];
        var ParticipantTeams = lineupsData.event.participantIds;
        var participants = lineupsData.participants;
        var participantKeys = Object.keys(participants);
        var participantOne = participants[teams[0]];
        var participantTwo = participants[teams[1]];
        var teamOneName = participantOne.name;
        var teamTwoName = participantTwo.name;
        this.setState({
            teamOneName: teamOneName,
            teamTwoName: teamTwoName
        })
        for (var i = 0; i < teams.length; i++) {
            var starters = lineups[teams[i]].starters;
            var substitutes = lineups[teams[i]].substitutes;
            var managers = lineups[teams[i]].managers;
            var formation = lineups[teams[i]].formation;
            var starterArray = [];
            var substituteArray = [];
            var managerArray = [];
            var lineupMembers = {};
            for (var j = 0; j < starters.length; j++) {
                for (var k = 0; k < participantKeys.length; k++) {
                    if (starters[j].id === participantKeys[k]) {
                        var startersObject = {
                            ...starters[j],
                            ...participants[participantKeys[k]]
                        }
                    }
                }
                starterArray.push(startersObject);
            }
            for (var l = 0; l < substitutes.length; l++) {
                for (var m = 0; m < participantKeys.length; m++) {
                    if (substitutes[l].id === participantKeys[m]) {
                        var substitutesObject = {
                            ...substitutes[l],
                            ...participants[participantKeys[m]]
                        }
                    }
                }
                substituteArray.push(substitutesObject);
            }
            for (var n = 0; n < managers.length; n++) {
                for (var o = 0; o < participantKeys.length; o++) {
                    if (managers[n].id === participantKeys[o]) {
                        var managersObject = {
                            ...managers[n],
                            ...participants[participantKeys[o]]
                        }
                    }
                }
                managerArray.push(managersObject);
            }
            lineupMembers['starters'] = starterArray;
            lineupMembers['substitutes'] = substituteArray;
            lineupMembers['members'] = managerArray;
            lineupMembers['formation'] = formation;
            lineupMembers['teamInfo'] = participants[teams[i]];
            lineupArray.push(lineupMembers);

        }
        this.setState({
            lineupArray: lineupArray
        })
    }

    generateImageUrl = (countryCode) => {
        if (this.state.countries.hasOwnProperty(countryCode)) {
            var url = this.state.countries[countryCode]['images']['flagCircle']['url'];
        }
        var imageUrl = url + "?rule=clip-16x16"
        return imageUrl;
    }

    generatePlayerLineupDiv = (teamarray) => {
        let players_div = teamarray.map(
            (player, index) => {
                var imageUrl = this.generateImageUrl(player.countryCode);
                return (
                    <div className="lineupmembers" key={"lineupmembers" + index}>
                        <LineupmembersComponent lineupTeam={player} imageUrl={imageUrl}></LineupmembersComponent>
                    </div>
                )
            }
        )
        return players_div;
    }

    generateMembersDiv = (members_array) => {
        let members_div = members_array.map(
            (member, index) => {
                var imageUrl = this.generateImageUrl(member.countryCode);
                return (
                    <div className="team-manager" key={"member" + index}>
                        <div className="team-manager-img">
                            <img src={imageUrl} />
                        </div>
                        <div className="team-manager-name">
                            {member.name}
                        </div>
                    </div>
                )
            }
        )
        return members_div;
    }

    generateLineupComponent = (lineupArray, indexCount) => {
        var teamLineup = lineupArray;
        var starterPlayers_div = this.generatePlayerLineupDiv(teamLineup.starters);
        var subPlayers_div = this.generatePlayerLineupDiv(teamLineup.substitutes);
        var memberDiv = this.generateMembersDiv(teamLineup.members);
        var formation = teamLineup.formation;
        var teamImageInfo = teamLineup.teamInfo.images;
        if (teamImageInfo.hasOwnProperty('clubLogo')) {
            var logoImage = teamImageInfo.clubLogo.url + "?rule=clip-32x32";
        }

        return (
            <div className="lineups" key={"lineup" + indexCount}>
                <div className='heading' >
                    <img className="team-logo" src={logoImage ? logoImage : teamBlankLogo} alt="" />
                    <p className="heading-lineup uppercase">Laguppställning</p>
                    <p className="heading-formation">Formation: {formation}</p>
                </div>
                <div className='players'>
                    {starterPlayers_div}
                </div>
                <div className='heading uppercase' >
                    Reserver
                </div>
                <div className='players'>
                    {subPlayers_div}
                </div>
                <div className='heading' >
                    Tränare
                </div>
                <div className='players'>
                    {memberDiv}
                </div>
            </div>
        )
    }

    render() {

        var listofTabs = [this.state.teamOneName, this.state.teamTwoName];
        var lineupComponentDiv = new Array(listofTabs.length).fill(null);
        var lineupArray = this.state.lineupArray;
        if (typeof lineupArray !== "undefined" && lineupArray !== null
            && lineupArray.length > 0 && typeof this.state.countries !== "undefined"
            && this.state.countries !== null) {
            for (var lineupArrayCount = 0; lineupArrayCount < lineupArray.length; lineupArrayCount++) {
                if (typeof lineupArray[lineupArrayCount] !== "undefined" &&
                    lineupArray[lineupArrayCount] !== null) {
                    lineupComponentDiv[lineupArrayCount] = this.generateLineupComponent(lineupArray[lineupArrayCount], lineupArrayCount);
                }
            }
        }
        if (this.state.loader === false) {
            return (
                <div className="">
                    <TabsComponent theme="teambar" components={lineupComponentDiv} listofTabs={listofTabs} generateTabURL={this.state.generateTabURL} />
                </div>
            )
        } else {
            return (
                <LoaderComponent ></LoaderComponent>
            )
        }

    }
}