import React, { Component } from "react";
import CompletedEvents from './CompletedEvents';
import LiveEvents from './LiveEvents';
import UpcomingEvents from './UpcomingEvents';
import { Link } from "react-router-dom";
export default class MalmoEvents extends Component {
    render() {
        return (
            <>
                <div className="malmo-sidebar-component">
                    <LiveEvents malmo={true} widgetCity={this.props.widgetCity}/>
                    <UpcomingEvents malmo={true} widgetCity={this.props.widgetCity}/>
                    <CompletedEvents malmo={true} widgetCity={this.props.widgetCity}/>
                </div>
            </>
        )
    }
}