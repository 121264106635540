import React from "react";
import Comments from "./Comments";
import ShowGrades from "./ShowGrades";
export default function EventData(props) {
  const {
    tagContent,
    game,
    onlyForFirst,
    team1Score,
    team2score,
    evenTime,
    expert,
    totalGames,
    gameCount,
    teamTwoName,
    teamOneName,
    team1Img,
    team2Img,
    event,
    odds,
    gameSuggestion,
    isStartpage
  } = props;
  const showTag = (res, tagContent) => {
    if (res) {
      return <h3 className="heading">{tagContent}</h3>;
    } else {
      return "";
    }
  };
  return (
    <div
      className="teaser-match"
      data-tracking-tag="bundle"
      data-test-id="bundle"
    >
      {showTag(onlyForFirst, tagContent)}
      <div className="teaser-match-heading">
        <div className={totalGames > 1 ? "teaser-match-number" : "hide"}>
          {gameCount}
        </div>
        <div className="team team-left">
          <div className="team-name" title={teamOneName}>
            {teamOneName}
          </div>
          <img src={team1Img} className="team-logo team-logo__right" alt="" />
        </div>
        {event.manual ? (
          <div style={{ width: "12%" }}></div>
        ) : event.status === "notStarted" ? (
          <div className="time">{evenTime}</div>
        ) : (
          <div className="time">
            {team1Score} - {team2score}
          </div>
        )}
        <div className="team">
          <img src={team2Img} className="team-logo team-logo__left" alt="" />
          <div className="team-name" title={teamTwoName}>
            {teamTwoName}
          </div>
        </div>
        <div className="teaser-match-link icon icon-chevron right"></div>
      </div>
      {event.status === "notStarted" ? odds : ""}
      {gameSuggestion}
      {!isStartpage ? (
        <ShowGrades
          team1Grade={game["team-a-grade"]}
          team2Grade={game["team-b-grade"]}
          gameValue={game["value"]}
        />
      ) : null}
      <Comments
        game={game}
        isStartpage={isStartpage}
        expert={expert}
      />
    </div>
  );
}
