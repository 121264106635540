import React from 'react'
import TodaysMatches from "./TodaysMatchesComponent";
import config from '../../config';
export default function TodaysCompletedEvents(props) {
    return (
        <>
            <TodaysMatches eventStatus={config.FINISHED}/>
        </>
    )
}
