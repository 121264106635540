import React, { Component } from "react";
import apicall from '../../utils/apicall';
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import helpers from "../../utils/helpers";
import teamBlankLogo from "../../images/team-blank-logo.svg"
import author_avatar from "../../images/avatar-author.png";
import config from '../../config';
export default class EventsListComponent extends Component {
    constructor(props) {
        console.log(props)
        super(props);
        this.state = {
            events: null,
            eventType: props.eventType,
            isLoading: true
        }
    }

    componentWillMount() {
        if (this.props.malmo) {
            let total = 100;
            apicall.getEvents(config.widgetCityMap[this.props.widgetCity],total).then((JsonEvents) => {
                var events = JsonEvents;
                this.setState({
                    events: events[this.props.eventType],
                    isLoading: false
                })
            });
        } else {
            apicall.getEvents(config.widgetCityMap[this.props.widgetCity]).then((JsonEvents) => {
                var events = JsonEvents;
                this.props.apicallStatus && this.props.apicallStatus()
                this.setState({
                    events: events[this.props.eventType],
                    isLoading: false
                })
            });
        }

    }

    generateCommentsDiv = (eventComments, team_info_keys) => {
        let team1Id = null;
        let team2Id = null;
        if (team_info_keys[0].position === 'home') {
            team1Id = team_info_keys[0];
            team2Id = team_info_keys[1]
        } else {
            team1Id = team_info_keys[1];
            team2Id = team_info_keys[2]
        }
        let commentsKeys = eventComments && Object.keys(eventComments);
        let comments = commentsKeys && eventComments[commentsKeys[0]];
        if (comments) {
            let filterdComments = null;
            let gameComment = comments.gameComment && comments.gameComment[0];
            let team1Comment = comments[team1Id] && comments[team1Id]['comments'][0];
            let team2Comment = comments[team2Id] && comments[team2Id]['comments'][0];
            let teamName = null;
            if (gameComment && gameComment.length > 0) {
                filterdComments = gameComment;
            } else if (team1Comment && team1Comment.length > 0) {
                filterdComments = team1Comment;
                teamName = comments[team1Id]['name']
            } else if (team2Comment && team2Comment.length > 0) {
                filterdComments = team2Comment;
                teamName = comments[team2Id]['name']
            }
            return (
                filterdComments ?
                    <div className="teaser-match-content">
                        <div className="teaser-match-content-editor">
                            <img src={comments.expert_image_url !== "" ? comments.expert_image_url : author_avatar} alt="" />
                        </div>
                        <div className="teaser-match-content-text">
                            <p><span style={{ fontWeight: "bold" }}>{teamName + " - "}</span>{helpers.formatStr(filterdComments)}</p>
                        </div>
                    </div>
                    : null

            )
        }

    }

    displayodds = (odds) => {
        if (odds !== null && typeof odds !== undefined) {
            var oddsKeys = Object.keys(odds);
            var oddsArray = [];
            oddsKeys.forEach(function (key) {
                oddsArray.push(odds[key]);
            });
            let oddsDiv = oddsArray.map(
                (odd, oddindex) => {
                    // var clientName = oddsKeys[oddindex];
                    var clientName = 'ODDS';
                    return (
                        <div className="teaser-match-odds" key={"odds" + oddindex}>
                            <div className="column teaser-match-odds-heading">{clientName}</div>
                            <div className="column">
                                <div className="teaser-match-odds-odds">
                                    {odd[1].value && odd[1].value.toFixed(2)}
                                </div>

                                <div className="teaser-match-odds-odds">
                                    {odd['X'].value && odd['X'].value.toFixed(2)}
                                </div>
                                <div className="teaser-match-odds-odds">
                                    {odd[2].value && odd[2].value.toFixed(2)}
                                </div>
                            </div>
                        </div>
                    );
                }
            )
            return oddsDiv;
        }
    }

    generateTournamentEvents = (events) => {
        var eventsData = events;
        var eventsDataKeys = Object.keys(eventsData);
        var eventsDataDiv = eventsDataKeys.map(
            (eventsDataKey, index) => {
                var sport = eventsData[eventsDataKey];
                if (typeof sport === "undefined" || sport === null) {
                    return null;
                }
                var tournamentKeys = Object.keys(sport);
                var tournamentDiv = tournamentKeys.map(
                    (tournamentKey, index) => {
                        var tournamentname = sport[tournamentKey].name;
                        var tournamentEvents = sport[tournamentKey].events;
                        return (
                            this.generateEvents(tournamentname, tournamentEvents)
                        )
                    }
                )
                return tournamentDiv;
            }
        )
        return eventsDataDiv;
    }


    generateEvents = (tournamentname, tournamentEvents) => {
        let eventsDiv = tournamentEvents.map(
            (tournamentEvent, index) => {
                var team_info_keys = Object.keys(tournamentEvent.team_info);
                var team1Name = "";
                var team2Name = "";
                var team1Img = "";
                var team2Img = "";
                var team1Id = "";
                var team2Id = "";
                if (team_info_keys.length !== 0) {
                    for (var i = 0; i < team_info_keys.length; i++) {
                        if (tournamentEvent.team_info[team_info_keys[i]].position === "home") {
                            team1Name = tournamentEvent.team_info[team_info_keys[i]].name;
                            team1Img = tournamentEvent.team_info[team_info_keys[i]].logo;
                            team1Img = team1Img ? (team1Img + "?rule=clip-32x32") : teamBlankLogo;
                            team1Id = team_info_keys[i];
                        }
                        if (tournamentEvent.team_info[team_info_keys[i]].position === "away") {
                            team2Name = tournamentEvent.team_info[team_info_keys[i]].name;
                            team2Img = tournamentEvent.team_info[team_info_keys[i]].logo;
                            team2Img = team2Img ? (team2Img + "?rule=clip-32x32") : teamBlankLogo;
                            team2Id = team_info_keys[i];
                        }
                    }
                }

                var oddsKey = Object.keys(tournamentEvent.odds);
                var odds = null;
                var comments = null;
                var commentsKeys = Object.keys(tournamentEvent.hasOwnProperty('comments') && tournamentEvent.comments);
                if (commentsKeys.length !== 0) {
                    comments = tournamentEvent.comments;
                }
                if (this.props.eventType === 'upcoming') {
                    var eventTime = dayjs(tournamentEvent.start_date).locale('sv').format('DD MMM')
                } else {
                    var eventTime = dayjs(tournamentEvent.start_date).format('HH:mm')
                }
                if (oddsKey.length !== 0) {
                    odds = tournamentEvent.odds;
                }
                var articleId = null;
                var article_info = tournamentEvent.article_info;
                if (article_info.hasOwnProperty('articleid')) {
                    articleId = article_info.articleid;
                }
                var sportName = tournamentEvent.sport;
                if (tournamentEvent.results[team1Id] && tournamentEvent.results[team2Id]) {
                    var team1Score = tournamentEvent.results[team1Id].hasOwnProperty("runningScore") ?
                        tournamentEvent.results[team1Id].runningScore : "";
                    var team2score = tournamentEvent.results[team2Id].hasOwnProperty("runningScore") ?
                        tournamentEvent.results[team2Id].runningScore : "";
                }
                let eventDate = dayjs(tournamentEvent.start_date).format('YYYY-MM-DD')
                let slugifiedTeamNames = helpers.slugifyText(team1Name) + "-vs-" + helpers.slugifyText(team2Name);
                let slugifiedTournamentName = helpers.slugifyText(tournamentname);
                if (articleId) {
                    var eventURL = "/" + tournamentEvent.eventid + "/" + articleId + "/" + config.mapSportName[sportName] + "/" +
                        slugifiedTournamentName + "/" + slugifiedTeamNames + "/" + eventDate;
                } else {
                    var eventURL = "/" + tournamentEvent.eventid + "/" + config.mapSportName[sportName] + "/" +
                        slugifiedTournamentName + "/" + slugifiedTeamNames + "/" + eventDate;
                }
                return (
                    <Link to={eventURL}
                        className={"teaser-match " + ((index > 0) ? 'reduce-margin' : '')} key={"event" + index}
                        onClick={() => helpers.scrollTo(document.documentElement, 0, 300, 'vertical')} target='_top'>
                        <h3 className={"heading " + ((index > 0) ? 'hide' : '')}>
                            {tournamentname}
                        </h3>
                        <div className="teaser-match-heading">
                            <div className="team team-left">
                                <div className="team-name" title={team1Name}>{team1Name}</div>
                                <img src={team1Img} className="team-logo team-logo__right" alt="" />
                            </div>
                            {tournamentEvent.status === 'notStarted' ?
                                (<div className="time">{eventTime}</div>) :
                                (<div className="time">{team1Score} - {team2score}</div>)
                            }
                            <div className="team">
                                <img src={team2Img} className="team-logo team-logo team-logo__left" alt="" />
                                <div className="team-name" title={team2Name}>{team2Name}</div>
                            </div>
                            <div className="teaser-match-link icon icon-chevron right"> </div>
                        </div>
                        {tournamentEvent.status !== "finished" && this.displayodds(odds)}
                        {this.generateCommentsDiv(comments, team_info_keys)}
                    </Link>
                );
            }
        )
        return eventsDiv;
    }

    formatStr = (str) => {
        if (str !== null) {
            return str.substring(0, 130) + '...';
        } else {
            return null;
        }
    }


    render() {
        if (this.state.events !== null && typeof this.state.events !== undefined &&
            Object.keys(this.state.events).length !== 0) {
            var events = this.state.events;
            return (
                <div className={this.props.className}>
                    {
                        !this.props.malmo ?
                            <h2 className="today-date">{this.props.heading} matcher</h2> :
                            <h2 className="today-date" style={{ marginTop: '20px' }}>{this.props.heading} matcher</h2>
                    }
                    {this.generateTournamentEvents(events)}
                </div>
            );
        } else {
            return null
        }
    }
}
