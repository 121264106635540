import React, { Component } from "react";

export default class TimeoutComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants,
            txt: this.props.txt
        }
    }

    render() {
        return (<li className="report-items-list-item">
            <div className="report-item report-item-timeout report-item-timeout report-item-ice-hockey">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/timeout.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-content">Timeout, Örebro</div>
                </div>
                <div className="report-item-time">{this.props.getElapsed}</div>
            </div>
        </li>);
    }
}