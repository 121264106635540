import React, { Component } from "react";
import apicall from "../utils/apicall"
import LoaderComponent from "../component/LoaderComponent"
import TabsComponent from "../component/TabsComponent";
import config from "../config";
import MobileNavbar from "./MobileNavbar";
import PageFooter from "./PageFooter";
import EventDetailsPageComponent from "./EventDetailsPageComponent"
import AftonNavbar from './AftonNavbar';
import TodaysMatchesComponent from "./todays_matches/TodaysMatchesComponent";
import TodaysEvents from "./todays_matches/TodaysEvent"
import helpers from "../utils/helpers";
import TipsetWidgetComponent from "./TipsetWidgetComponent";
export default class SearchEventComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventDetails: null,
            loader: true,
            articleID: null,
            gameCount: null,
            sportName: null,
            article: null,
            template: null
        };
    }

    componentDidMount() {
        var eventId = null;
        if (this.props.match) {
            eventId = this.props.match.params.eventId;
            var gameCount = this.props.match.params.gameCount;
            var articleId = this.props.match.params.articleId;
            if (articleId === "null" || articleId === 0) {
                articleId = null;
            }
            var sportName = this.props.match.params.sportName;
            var template = this.props.match.params.template;
            this.setState({
                articleID: articleId,
                sportName: sportName,
                gameCount: gameCount,
                template: template
            });
        }
        this.fetchEventDetails(this.props.match.params.eventId)
        if (this.props.match.params.articleId !== null && this.props.match.params.articleId !== "null"
            && this.props.match.params.articleId !== 0 && this.props.match.params.articleId !== "0") {
            this.fetchArticleDetails(this.props.match.params.articleId)
        }
    }

    fetchArticleDetails = (articleId) => {
        articleId && apicall.fetchArticleById(articleId).then((articleJson) => {
            var article = articleJson;
            this.setState({
                article: article,
                articleId: articleId
            })
        });

    }

    fetchEventDetails = (eventId) => {
        this.setState({
            eventId: eventId
        })
        apicall.fetchEvents(eventId).then(
            jsonEvent => {
                var eventDetails = jsonEvent;
                this.setState({
                    eventDetails: eventDetails,
                    loader: false
                });
            }
        )
    }

    render() {
        if (this.props.match) {
            var paramEventID = this.props.match.params.eventId;
            var currentEventID = this.state.eventId;
            if ((paramEventID !== currentEventID) && typeof currentEventID !== "undefined") {
                //Execute the Function only once in 100 ms 
                helpers.debounced(100, this.fetchEventDetails(this.props.match.params.eventId));
                if (this.props.match.params.articleId !== null && this.props.match.params.articleId !== "null"
                    && this.props.match.params.articleId !== 0 && this.props.match.params.articleId !== "0") {
                    helpers.debounced(100, this.fetchArticleDetails(this.props.match.params.articleId));
                }
                else {
                    this.setState({
                        article: null,
                        articleID: null
                    })
                }
            }
        }

        var listofTabsMap = config.listofTabsMap;
        var listOfMenu = [];
        let tabsKeys = Object.keys(listofTabsMap);
        for (let tab of tabsKeys) {
            listOfMenu.push(tab);
        }
        var componentsArray = new Array(listOfMenu.length).fill(null);
        if (this.state.eventDetails !== null) {
            var eventDetails = this.state.eventDetails;
            var template = null;
            if (this.props.match) {
                template = this.props.match.params.template;
            }
            var event = eventDetails.events[0];
            var team_info = event.team_info;
            var teams_key = Object.keys(team_info);
            let activeTab = this.state.activeTab;
            var participantObj = {
                "eventID": event.eventid,
                "team1": teams_key[0],
                "team1Name": team_info[teams_key[0]]['name'],
                "team2": teams_key[1],
                "team2Name": team_info[teams_key[1]]['name']
            };
            var eventComponentDiv =  <div className="search-component" key={event.eventid}>
                <EventDetailsPageComponent event={event} gameCount={this.state.gameCount} articleID={this.state.articleID} article={this.state.article} participantsData={participantObj} sportName={this.state.sportName} isSearch={true} collapse={true} />
            </div>
            if (typeof template !== "undefined" && template !== null && template !== '') {
                var templateIndex = listOfMenu.findIndex(item => (template.toLowerCase() === item.toLowerCase() || (config.mapTamplates[template] && config.mapTamplates[template].toLowerCase() === item.toLowerCase())));
                if (templateIndex > -1) {
                    componentsArray[templateIndex] = eventComponentDiv;
                    activeTab = templateIndex + 1;
                }
                else {
                    if (typeof template !== "undefined" && template !== "null" && template !== "0") {
                        //Important OtherWise The Menu Entries Will be Added again
                        listOfMenu = helpers.immutablePush(listOfMenu, helpers.capitalizeFirstLetter(template));
                        componentsArray[listOfMenu.length - 1] = eventComponentDiv;
                        activeTab = listOfMenu.length;
                    }
                    else {
                        componentsArray[0] = eventComponentDiv;
                        activeTab = 1;
                    }

                }
            }
            else {
                componentsArray[0] = eventComponentDiv
            }
            if (this.state.loader) {
                return (
                    <LoaderComponent></LoaderComponent>
                )
            } else {
                return (
                    <React.Fragment>
                        <AftonNavbar />
                        <div className="ab-theme-gradient-page">
                            <div className="article-main-page">
                                <div className="article">
                                    <main>
                                        <div className="article-main-box">
                                            <TabsComponent components={componentsArray} listofTabs={listOfMenu}
                                                theme='menubar' activeTab={activeTab} listofTabsMap={config.listofTabsMap}
                                                subactiveTab={activeTab} horizontalScroll={true} homepageFilter={true} />
                                        </div>
                                    </main>
                                    <aside className="events-sidebar-component">
                                        <TodaysEvents filterSports={true} sportName={this.state.sportName} />
                                    </aside>
                                </div>
                            </div>
                        </div>
                        <PageFooter />
                        <MobileNavbar />
                    </React.Fragment >
                )
            }
        }
        else {
            return null;
        }
    }
}