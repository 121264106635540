import React, { Component } from "react";
import { Link } from "react-router-dom";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from 'dayjs';
import teamBlankLogo from "../images/team-blank-logo.svg"
import config from "../config.js"
import apicall from "../utils/apicall";
import helpers from '../utils/helpers'
import { withRouter } from 'react-router-dom'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import _ from 'lodash';
import demo from '../demo'
import avatar from '../images/expert.png'
import NumberFormat from 'react-number-format'
import Axios from "axios";
dayjs.extend(weekOfYear);
class TipsetWidgetComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            article: this.props.article,
            templateName: this.props.templateName,
            forceShowWidget: this.props.forceShowWidget,
            expert: null,
            useEventObject: this.props.useEventObject,
            games: null,
            expertSuggestions: [],
            selectedTab: 0,
            showWidget: true,
            experts: [],
            selectedExpert: 0,
            showDropdown: false,
            selectedExpertMail: '',
            removeWidget: false,
            redirectURL: null
        }
    }



    displayodds() {
        if (this.state.event) {
            var event = this.state.event;
            if (event.odds !== null && typeof event.odds !== 'undefined') {
                var odds = event.odds;
                var oddskey = Object.keys(odds);
                if (oddskey.length !== 0) {
                    var res = [];
                    for (let oddComp in odds) {
                        res.push(
                            <div className="teaser-match-odds">
                                <div className="column teaser-match-odds-heading">ODDS</div>
                                <div className="column">
                                    <div className="teaser-match-odds-odds">{odds[oddComp][1].value && odds[oddComp][1].value.toFixed(2)}</div>
                                    <div className="teaser-match-odds-odds">{odds[oddComp]['X'].value && odds[oddComp]['X'].value.toFixed(2)}</div>
                                    <div className="teaser-match-odds-odds">{odds[oddComp][2].value && odds[oddComp][2].value.toFixed(2)}</div>
                                </div>
                            </div>
                        )
                    }
                    return res;
                } else {
                    return null;
                }
            }
        }
    }

    toggleDropdown = () => {
        this.setState({ showDropdown: !this.state.showDropdown })
    }

    handleClick(index, name, event) {
        let suggestionArray = this.state.suggestionArray;
        suggestionArray[index][name] = !suggestionArray[index][name];
        this.setState({ suggestionArray: suggestionArray })
        let selectedSuggestions = {};
        selectedSuggestions.selectedTab = this.state.selectedTab;
        selectedSuggestions.suggestionArray = suggestionArray
        config.localforage.setItem('selectedSuggestions', selectedSuggestions)
    }

    handleSubmit = () => {
        let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        let suggestionArray = this.state.suggestionArray;
        let system = 'single';
        const reducer = (accumulator, currentValue) => {
            let game = ""
            if (currentValue[1])
                game = game + '1'
            if (currentValue['X'])
                game = game + 'X'
            if (currentValue[2])
                game = game + '2'
            accumulator.push(game)
            return accumulator
        };
        let suggestions = suggestionArray.reduce(reducer, []).join(",");
        if (suggestions.trim().length > 25) system = "math";
        let requestData = {};
        requestData.system = system;
        requestData.drawNumber = this.state.draw;
        requestData.items = [suggestions];
        requestData.client = config.svenskaspelClient;
        requestData.retailer = config.svenskaspelRetailer;
        let clickObject = {};
        clickObject.partner = config.partner;
        clickObject.partner_domain = config.partnerDomain;
        clickObject.requested_data = requestData;
        clickObject.game_type = 'stryktipset';
        clickObject.article_id = this.props.articleID || (!_.isEmpty(this.state.response[0].suggestions[this.state.selectedExpert]) && this.state.response[0].suggestions[this.state.selectedExpert].article);
        let expert = this.state.experts[this.state.selectedExpert]
        clickObject.expert = _.isEmpty(expert && expert[Object.keys(expert)[0]]['name']) ? "" : expert[Object.keys(expert)[0]]['name']
        apicall.registerClick(clickObject).then(
            resp => {
                if (resp.status === 200 && resp.data.status) {
                    // this.props.history.push(resp.data.url)
                    this.setState({redirectURL:resp.data.url},()=>document.getElementById('triggerRedirect').click())
                }
            }
        )
    }

    showMatchBetting(index, suggestionArray, svenskaspel_odds, distribution, news_paper_advice, defaultSuggestionsArray) {
        let suggestion = suggestionArray && suggestionArray[index];
        let defaultSuggestions = defaultSuggestionsArray && defaultSuggestionsArray[index];
        return (
            <div>
                {suggestion &&
                    <div className="event-grades">
                        <div className="game-suggestions">
                            <button onClick={(e) => this.handleClick(index, "1", e)} className={"game-suggestion " + (defaultSuggestions && defaultSuggestions["1"] ? "red-border " : "gray-border ") + (suggestion["1"] ? "selected" : "")}> 1 </button>
                            <button onClick={(e) => this.handleClick(index, "X", e)} className={"game-suggestion " + (defaultSuggestions && defaultSuggestions["X"] ? "red-border " : "gray-border ") + (suggestion["X"] ? "selected" : "")}> x </button>
                            <button onClick={(e) => this.handleClick(index, "2", e)} className={"game-suggestion " + (defaultSuggestions && defaultSuggestions["2"] ? "red-border " : "gray-border ") + (suggestion["2"] ? "selected" : "")}> 2 </button>
                        </div>
                    </div>
                }
                {svenskaspel_odds ?
                    (<div className="teaser-match-odds">
                        <div className="column">Odds</div>
                        <div className="column">
                            <div className="teaser-match-odds-odds">{svenskaspel_odds['home']}</div>
                            <div className="teaser-match-odds-odds">{svenskaspel_odds['draw']}</div>
                            <div className="teaser-match-odds-odds">{svenskaspel_odds['away']}</div>
                        </div>
                    </div>) : null}
                {distribution ? (<div className="teaser-match-odds">
                    <div className="column">Svenska folket</div>
                    <div className="column">
                        <div className="teaser-match-odds-odds">{distribution['home']}%</div>
                        <div className="teaser-match-odds-odds">{distribution['draw']}%</div>
                        <div className="teaser-match-odds-odds">{distribution['away']}%</div>
                    </div>
                </div>) : null}
                {news_paper_advice ? (<div className="teaser-match-odds">
                    <div className="column">Tio tidningar</div>
                    <div className="column">
                        <div className="teaser-match-odds-odds">{news_paper_advice['home']}</div>
                        <div className="teaser-match-odds-odds">{news_paper_advice['draw']}</div>
                        <div className="teaser-match-odds-odds">{news_paper_advice['away']}</div>
                    </div>
                </div>) : null}
            </div>
        )
    }

    switchExpert = (event, index) => {
        let callApi = false
        let selectedExpert = index;
        this.toggleDropdown()
        this.setState({ selectedExpert: selectedExpert })
        this.getSuggestions(callApi, 0, selectedExpert)
        //this.switchTab(event, 0)
    }

    switchTab = (event, index) => {
        this.setState({
            selectedTab: index,
        })
        var suggestionArray = [];
        config.localforage.getItem('selectedSuggestions').then(
            resp => {
                if (resp && resp.selectedTab === index)
                    this.setState({ suggestionArray: resp.suggestionArray })
                else {
                    this.setState({ suggestionArray: helpers.generateSuggestionArray(this.state.expertSuggestions[index].suggestions) })
                }
            }
        )
        this.setState({ defaultSuggestions: helpers.generateSuggestionArray(this.state.expertSuggestions[index].suggestions) })
    }

    displayTabsInfo = () => {
        var tabContent = []
        var expertSuggestions = this.state.expertSuggestions;
        var tabIndex = this.state.selectedTab;
        if (expertSuggestions) {
            expertSuggestions.map((suggestion, index) => {
                tabContent.push(
                    <div className={tabIndex === index ? "col selected" : "col"} onClick={(e) => this.switchTab(e, index)}>
                        {suggestion.title}
                    </div>
                )
            })
        }
        return tabContent;
    }

    displayEventsInfo = (games, templateName) => {
        var templateContent = []
        var suggestions = this.state.suggestions;
        var suggestionArray = this.state.suggestionArray;
        var defaultSuggestions = this.state.defaultSuggestions;
        for (let eachGame of games) {
            var gameCount = eachGame.game;
            var evenTime = dayjs(eachGame.start_date).locale("sv").format('HH:mm');
            var eventid = eachGame.eventid;
            var team_info = eachGame.team_info;
            var team_info_keys = Object.keys(team_info)
            var home_team = {};
            var away_team = {};
            if (team_info[team_info_keys[0]]['position'] === "away") {
                home_team = team_info[team_info_keys[1]];
                away_team = team_info[team_info_keys[0]];
            } else {
                home_team = team_info[team_info_keys[0]];
                away_team = team_info[team_info_keys[1]];
            }
            var team1Img = home_team['logo'];
            var team2Img = away_team['logo'];
            team1Img = team1Img ? (team1Img + "?rule=clip-32x32") : teamBlankLogo;
            team2Img = team2Img ? (team2Img + "?rule=clip-32x32") : teamBlankLogo;
            var team1Name = home_team['name'];
            var team2Name = away_team['name'];
            var articleId = 0;
            let sportName = config.mapSportSwedish[eachGame.sport];
            let slugifiedTeamNames = helpers.slugifyText(team1Name) + "-vs-" + helpers.slugifyText(team2Name);
            let eventDate = dayjs(eachGame.start_date).format('YYYY-MM-DD')
            let slugifiedTournamentName = "default"
            if (articleId) {
                var eventURL = "/" + eventid + "/" + articleId + "/" + sportName + "/" +
                    slugifiedTournamentName + "/" + slugifiedTeamNames + "/" + eventDate;
            } else {
                var eventURL = "/" + eventid + "/" + sportName + "/" +
                    slugifiedTournamentName + "/" + slugifiedTeamNames + "/" + eventDate;
            }
            templateContent.push(
                <div className="teaser-match remove-margin" data-tracking-tag="bundle" data-test-id="bundle">
                    <div className="teaser-match-heading">
                        <div className='teaser-match-number'>{gameCount}</div>
                        <div className="team team-left">
                            <div className="team-name" title={eachGame["team-a"]}>{team1Name}</div>
                            <img src={team1Img} className="team-logo team-logo__right" alt="" />
                        </div>
                        <div className="time">{evenTime}</div>
                        <div className="team">
                            <img src={team2Img} className="team-logo team-logo__left" alt="" />
                            <div className="team-name" title={eachGame["team-b"]}>{team2Name}</div>
                        </div>
                    </div>
                    {this.showMatchBetting(gameCount - 1, suggestionArray, eachGame['svenskaspel_odds'],
                        eachGame['distribution'], eachGame['news_paper_advice'], defaultSuggestions)}
                </div>)
        }
        return templateContent;
    }

    extractName = (email) => {
        if (email.includes("@")) {
            return email.substring(0, email.lastIndexOf("@")).toString();
        } else {
            return email;
        }
    }

    refineSuggestions = (resp, tabIndex, selectedExpert, fetchDefault) => {
        if (resp && resp[0]) {

            let experts = [];
            for (let suggestions of resp[0].suggestions) {
                experts.push(suggestions.expert)
            }
            let expertSuggestions = _.isEmpty(resp[0].suggestions[selectedExpert]) ? [] : resp[0].suggestions[selectedExpert].suggestions
            this.setState({
                expertSuggestions: expertSuggestions,
                experts: experts
            })
            if (fetchDefault) {
                for (let index in expertSuggestions) {
                    if (expertSuggestions[index]['is_default'] === true) {
                        tabIndex = Number(index)
                        break;
                    }
                }
            }
            this.setState({ selectedTab: tabIndex })
            var suggestionArray = [];
            if (!_.isEmpty(resp[0].suggestions)) {
                config.localforage.getItem('selectedSuggestions').then(
                    response => {
                        if (response && response.selectedTab) {
                            this.setState({ suggestionArray: response.suggestionArray, selectedTab: response.selectedTab })
                        }
                        else {
                            let suggestions = resp[0].suggestions[selectedExpert] && resp[0].suggestions[selectedExpert].suggestions[tabIndex].suggestions;
                            this.setState({ suggestionArray: helpers.generateSuggestionArray(suggestions) })
                        }
                    }
                )
                let defaultSuggestions = helpers.generateSuggestionArray(expertSuggestions[tabIndex].suggestions)
                this.setState({ defaultSuggestions: defaultSuggestions })

            }
            else {
                this.setState({
                    noExpert: true
                })
                let gameCount
                if (this.state.template === 'stryktipset' || 'europatipset') gameCount = 13
                else if (this.state.template === 'topptipset') gameCount = 8

                config.localforage.getItem('selectedSuggestions').then(
                    response => {
                        if (response && _.has(response, 'suggestionArray')) {
                            this.setState({ suggestionArray: response.suggestionArray, selectedTab: response.selectedTab })
                        }
                        else {
                            for (let i = 0; i < gameCount; i++) {
                                let suggestionObject = {}
                                suggestionObject['1'] = false
                                suggestionObject['X'] = false
                                suggestionObject['2'] = false
                                suggestionArray.push(suggestionObject)
                            }
                            this.setState({ suggestionArray: suggestionArray })
                        }
                    }
                )


            }
        }
    }

    getSuggestions = (callApi, tabIndex, selectedExpert) => {
        //let dummy = demo.suggestions;
        let fetchDefault = true;
        if (callApi) {
            apicall.fetchSuggestions(this.props.templateName).then(
                resp => {
                    if (resp && resp[0]) {
                        for (let index in resp[0].suggestions) {
                            if (resp[0].suggestions[index].article === this.props.articleID) {
                                selectedExpert = index
                                this.setState({ selectedExpert: selectedExpert })
                            }
                        }
                        this.setState({ removeWidget: false })
                    } else {
                        this.setState({ removeWidget: true })
                    }
                    this.setState({ response: resp })
                    this.refineSuggestions(resp, tabIndex, selectedExpert, fetchDefault)
                })
        } else {
            let response = this.state.response;
            this.refineSuggestions(response, tabIndex, selectedExpert, fetchDefault)
        }
    }

    componentDidMount() {
        if (window.screen.width < 500 && !this.state.forceShowWidget) {
            this.setState({ showWidget: false })
        }
    }

    componentWillReceiveProps(newprops) {
        if (!this.state.showWidget && newprops.forceShowWidget) {
            this.setState({
                showWidget: newprops.forceShowWidget
            })
        }
        if (this.state.showWidget && this.state.response[0]) {
            let i = 0;
            for (let suggestion of this.state.response[0].suggestions) {
                if (suggestion.article === newprops.articleID && i !== this.selectedExpert) {
                    this.setState({
                        selectedExpert: i
                    })
                }
                i = i + 1
            }
        }
    }

    componentWillMount() {
        if (this.state.templateName) {
            var templateName = this.state.templateName;
            Axios.get(config.baseURL + "v1/events/template_games?template=" + templateName.toLowerCase(),
                {
                    validateStatus: function (status) {
                        return status < 400
                    }
                }
            ).then(jsondata => {
                var gamesArray = jsondata.data.games;
                this.setState({
                    games: gamesArray,
                    draw: jsondata.data.draw,
                    closeTime: jsondata.data.close_time,
                    turnover: jsondata.data.turnover
                });
            }).catch(function (error) {
                console.log("error while fetching events")
            });
        }
        if (this.props.templateName) {
            let callApi = true
            this.getSuggestions(callApi, this.state.selectedTab, 0)
        }
    }

    handleExpandClick = event => {
        event.preventDefault();
        if (this.state.showWidget === false) {
            this.props.handleButton();
            this.setState({
                showWidget: !this.state.showWidget
            })
        }
    }

    handleRadioClick = event => {
        this.props.handleButton();
        this.setState({
            showWidget: event.target.checked
        })
    }

    expertSelectorDiv = () => {
        let expertSelectorDiv = this.state.experts.map(
            (expert, index) => {
                let imageUrl = _.isEmpty(expert[Object.keys(expert)[0]]['image_url']) ? avatar : expert[Object.keys(expert)[0]]['image_url']
                return (<div key={index} className="tipset-header-image"
                    onClick={(e) => this.switchExpert(e, index)}>
                    <img className="" src={imageUrl} alt="" />
                    <div className="expert-name">{expert[Object.keys(expert)[0]]['name']}</div>
                </div>)
            }
        )
        return expertSelectorDiv;
    }

    render() {
        if (this.state.removeWidget) {
            return "";
        }
        if (dayjs(this.state.closeTime).add(1, 'hour').isBefore(dayjs(new Date()))) {
            config.localforage.removeItem('selectedSuggestions').then(
                resp => {
                    console.log('cache cleared')
                }).catch(
                    err => {
                        console.log('errors while clearing cache')
                    })
        }
        if (this.state.games) {
            var templateName = "";
            if (this.state.templateName) {
                templateName = this.state.templateName;
            }
            let totalAmount = 1;
            let suggestionArray = this.state.suggestionArray;
            if (suggestionArray) {
                for (let suggestion of suggestionArray) {
                    let count = 0;
                    for (let value of Object.values(suggestion)) {
                        if (value) count++;
                    }
                    if (count > 0)
                        totalAmount = totalAmount * count;
                }
            }
            let selectionCompleted = _.some(suggestionArray, { '1': false, 'X': false, '2': false })
            let expert = this.state.experts[this.state.selectedExpert]
            let headerImageUrl = _.isEmpty(expert && expert[Object.keys(expert)[0]]['image_url']) ? avatar : expert[Object.keys(expert)[0]]['image_url']
            var expertname = _.isEmpty(expert && expert[Object.keys(expert)[0]]['name']) ? "" : expert[Object.keys(expert)[0]]['name']
            var disableButton = !this.state.showWidget || selectionCompleted || totalAmount > config.maxAmountWidget ? true : false;
            return (
                <>
                    <div className={this.state.showWidget ? 'tipset-widget open' : 'tipset-widget'}>
                        <div className="tipset-header"
                            onClick={!this.state.showWidget && window.screen.width < 500 ? this.handleExpandClick : null}
                        >
                            <div className="tipset-annons">
                                <div className="tipset-annons-head">ANNONS</div>
                                <div className="tipset-annons-text">Åldersgräns för spel 18 år</div>
                                <div className="tipset-annons-icon"></div>
                            </div>
                            {/* <select name="select" onChange={this.switchExpert}>
                                {this.state.experts.map(
                                    (expert, index) => {
                                        return (<option value={index} selected={this.state.selectedExpert === index}>{index}</option>)
                                    }
                                )}
                            </select> */}
                            <div className={this.state.noExpert ? 'hide' : 'tipset-header-image'}
                                onClick={this.toggleDropdown}>
                                <img className="" src={headerImageUrl} alt="" />
                                <div className="tipset-header-image-heading">Tips</div>
                            </div>
                            <div className={this.state.showDropdown ? 'expert-selector' : 'hide'}>
                                {this.expertSelectorDiv(this.state.experts)}
                            </div>
                            <div className="tipset-header-text hide">{templateName}</div>
                            <div className="tipset-header-logo {templateName}"></div>
                            <div className="tipset-header-week">Vecka {dayjs(new Date).week()}</div>
                            <div className={!this.state.noExpert ? 'tipset-header-tag' : 'tipset-header-tag no-expert'}>
                                {!this.state.noExpert &&
                                    <tag-expert-text>
                                        {"Ta rygg på " + expertname}<span>s förslag</span> {" eller "}
                                    </tag-expert-text>
                                }
                                <button className="link" disabled={this.state.showWidget}
                                    onClick={this.handleExpandClick}>ändra<span> i kupongen</span></button>
                            </div>
                            <div className="tipset-header-bottom">
                                <div className="tipset-header-gamestop-time">Spelstopp: {dayjs(this.state.closeTime).format("D/MM HH:mm")}</div>
                                <div className="tipset-header-game-turnaround">Omsättning: <NumberFormat value={this.state.turnover} displayType={'text'} thousandSeparator={" "} /> kr</div>
                            </div>
                            <div className={this.state.showWidget ? 'tipset-widget-system' : 'hide'} >
                                {this.displayTabsInfo()}
                            </div>
                        </div>
                        <div className="tipset-widget-body">
                            <div className="tipset-tabs"></div>
                            {this.displayEventsInfo(this.state.games, templateName)}
                        </div>
                        <div className="tipset-footer"
                            onClick={!this.state.showWidget && window.screen.width < 500 ? this.handleExpandClick : null}
                        >
                            <div className="switch right">
                                <input type="checkbox" id="switch1" className="switch__input" checked={this.state.showWidget}
                                    onChange={this.handleRadioClick} />
                                <label for="switch1" className="switch__label">Visa kupong</label>
                            </div>
                            <button disabled={disableButton}
                                className="tipset-footer-button"
                                onClick={this.state.showWidget ? this.handleSubmit : null}>
                                <div className="amount">{totalAmount} kr</div>Spela
                            </button>
                        </div>
                        <a id="triggerRedirect" href={this.state.redirectURL} style={{display:'none'}}></a>
                       
                    </div>
                </>
            )
        } else {
            return null;
        }
    }
}
export default withRouter(TipsetWidgetComponent);

