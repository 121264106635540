import config from "../config";
import slugify from 'slugify';
import dayjs from 'dayjs';
var helpers = {

    slugify: function (array) {
        var tabsMenuMap = {};
        if (typeof array !== 'undefined' && array != null) {
            for (let tab of array) {
                let tabKey = slugify(tab).toLowerCase();
                tabsMenuMap[tabKey] = tab;
            }
            return tabsMenuMap;
        }
    },
    slugifyText: function (text) {
        if (typeof text !== 'undefined' && text !== null) {
            return slugify(text, {
                replacement: '-',
                remove: /[*+~.,()'":@!#?/]/g,
                lower: true
            })
        }
    },
    getUrlDate: function (text) {
        if (typeof text !== 'undefined' && text !== null) {
            // let n = text.length;
            // return text.slice(n - 10);
            var regEXP = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
            var dateArray = regEXP.exec(text);
            return dateArray ? dateArray[0] : null;
        }
    },
    replaceText: function (text, date) {
        if (typeof text !== 'undefined' && text !== null) {
            return text.replace("date_replace", date);
        }
    },
    replaceAll: function (str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    },
    formatStr: function (str) {
        if (typeof str !== "undefined" && str.length > 183) {
            return str.substring(0, 180) + '...';
        }
        return str;
    },
    generateSuggestionArray: function (suggestions) {
        let suggestionsArray = []
        suggestions.split(",").map(
            (suggestion, index) => {
                let obj = {}
                obj['1'] = suggestion.indexOf("1") !== -1 ? true : false
                obj['X'] = (suggestion.indexOf("X") !== -1 || suggestion.indexOf("x") !== -1) ? true : false
                obj['2'] = suggestion.indexOf("2") !== -1 ? true : false
                suggestionsArray.push(obj)
            },
        )
        return suggestionsArray;
    },
    isEmpty: function (obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }

        return JSON.stringify(obj) === JSON.stringify({});
    },
    debounced: function (delay, fn) {
        let timerId;
        return function (...args) {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => {
                fn(...args);
                timerId = null;
            }, delay);
        }
    },
    generateBettingUrl: function (outcomeid, betting_offer_id, stake) {
        let bettingBaseUrl = config.baseURL + "/register_click?outcomeid=";
        return bettingBaseUrl + outcomeid + "&betting_offer_id=" + betting_offer_id + "&stake=" + stake;
    },
    immutableUnshift: function (arr, newEntry) {
        return [newEntry, ...arr]
    },
    immutablePush: function (arr, newEntry) {
        return [...arr, newEntry]
    },
    capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    pad: function (str, size) {
        var str = String(str);
        while (str.length < (size || 2)) { str = "0" + str; }
        return str;
    },
    compare: function (a, b) {
        if (a.offset < b.offset)
            return -1;
        if (a.offset > b.offset)
            return 1;
        return 0;
    },
    replace: function (str) {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
    },
    boldString: function (str, find) {
        var re = new RegExp(find, 'ig');
        return str.replace(re, '<b>' + find + '</b>');
    },
    isTouchDevice: function () {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function (query) {
            return window.matchMedia(query).matches;
        }
        if (('ontouchstart' in window) || window.DocumentTouch) {
            return true;
        }
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    },
    scrollTo: function (element, to, duration, direction) {
        let directionScroll = (direction === 'vertical') ? 'scrollTop' : 'scrollLeft';
        if (duration <= 0) return;
        var difference = to - element[directionScroll];
        var perTick = difference / duration * 10;

        setTimeout(() => {
            element[directionScroll] = element[directionScroll] + perTick;
            if (element[directionScroll] === to) return;
            this.scrollTo(element, to, duration - 10, direction);
        }, 10);
    },
    hasScroll: function (el, direction, step) {
        direction = (direction === 'vertical') ? 'scrollTop' : 'scrollLeft';
        if (el) {
            let scrollVal = parseInt(el[direction] + step);
            let scrollWidth = parseInt(el["scrollWidth"] - el["offsetWidth"]) - 20;
            if (step != 0) {
                this.scrollTo(el, scrollVal, 40, 'horizontal');
            }
            if (el[direction] < 20) {
                return config.display.hideLeft;
            }
            else if (scrollVal > scrollWidth) {
                return config.display.hideRight;
            }
            else {
                return config.display.displayBoth;
            }
        }
    },
    removeMetaTags: function () {
        // document.querySelector('title') && document.querySelector('title').remove() 
        // document.querySelector('meta[property="og:title"]') && document.querySelector('meta[property="og:title"]').remove()
    }

}

export default helpers;