import React from 'react'
import Helmet from 'react-helmet'
import config from '../config'
import helpers from '../utils/helpers'
import _ from 'lodash'
const Meta = props => {
    const pathName = props.location.pathname;
    console.log(props);
    let cannonicalUrl = null;
    if(pathName === '/'){
      cannonicalUrl = 'https://www.aftonbladet.se/sportbladet/speltips';
    }else{
      cannonicalUrl = 'https://www.aftonbladet.se/sportbladet/speltips'+ _.toLower(pathName);
    }
    const path = props.path;
    const seoTitle = config.seoTitle;
    const metaDescription = config.metaDescription;
    var title = null;
    var description = null;
    var stryktipsetArticle = false;
    if (props.computedMatch.params.hasOwnProperty('template')) { 
      const templateName = props.computedMatch.params.template;        
      const pageTitle = props.computedMatch.params.title;
      const seoTemplateArray = config.seoTemplateArray;
      let date = helpers.getUrlDate(pageTitle)
      if (typeof templateName !== 'undefined' && (templateName === 'stryktipset' || templateName === 'powerplay' || templateName === 'spelpaus')) {
        if (templateName === 'stryktipset') stryktipsetArticle = true;
        let temp = config.articleSeoTitle[templateName];
        title = helpers.replaceText(temp,date)
      } else if (typeof templateName !== 'undefined' && seoTemplateArray.includes(templateName) ){     
        title = helpers.slugifyText(pageTitle) + '| Aftonbladet';
      }
    } else if (props.computedMatch.params.hasOwnProperty('teams') && props.computedMatch.params.hasOwnProperty('eventDate')) {
      let teams = props.computedMatch.params.teams;
      let evendate = props.computedMatch.params.eventDate;
      let teamnames = helpers.replaceAll(teams, '-', ' ');
      title = "Odds " + teamnames + " " + evendate + " | Aftonbladet";
      description = helpers.replaceText(metaDescription['game'], teamnames)
    } else {
      var pathSlug = (path === '/' ? path : helpers.slugifyText(path));
      if (seoTitle.hasOwnProperty(pathSlug)) {
        title = seoTitle[pathSlug];
      }
      if (metaDescription.hasOwnProperty(pathSlug)) {
        description = metaDescription[pathSlug];
      }
    }

    // var fbtags = [];

    // if(stryktipsetArticle) {
    //     fbtags.push(<meta property="og:url" content={cannonicalUrl} />);
    // }

  return (
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, 
            maximum-scale=1.0, user-scalable=no"/>
          {title && <title>{title}</title>}
          {description && <meta name='description' content={description} />}   
          {title && <meta property="og:title" content={title} />}       
          {description && <meta property="og:description" content={description} />}       
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@aftonbladetSpeltips"/>
        <meta name="twitter:creator" content="@aftonbladetSpeltips"/>
        <meta name="twitter:title" content="Speltips Sportbladet | Aftonbladet"/>
        <meta name="twitter:description" content="Sportbladets spelexperter har levererat speltips sedan 1927. Dagliga analyser med odds från ett flertal spelbolag samt bolagsspel."/>
        <meta name="twitter:image" content="https://www.aftonbladet.se/images/socials.jpg"/>
        <link rel="canonical" href={cannonicalUrl} />
        <meta property="og:url" content={cannonicalUrl} />      
        {/* { stryktipsetArticle && fbtags} */}
      </Helmet>
  )
}

export default Meta;