import React from 'react'
import TodaysCompletedEvents from './TodaysCompletedEvents';
import TodaysMatchesLive from './TodaysMatchesLive';
export default function TodaysEvent(props) {
    return (
        <>
            <TodaysMatchesLive filterSports={props.filterSports} sportName={props.sportName}/>
            <TodaysCompletedEvents filterSports={props.filterSports} sportName={props.sportName}/>
        </>
    )
}
