import localforage from "localforage";
// staging url
const URL = "https://ab-liveodds.cortexcraft.com/"
// production url
// const URL = "https://ab-affiliate.dunderdog.se/"
const config = {
  isProduction: false,
  displayAds: false,
  baseURL: `${URL}api/`,
  affiliateBaseURL:URL,
  localforage: localforage.createInstance({
    name: "spotbladet"
  }),
  baseUrlName: '/sportbladet/speltips',
  partner:'Svenskaspel',
  partnerDomain: 'Sweden',
  svenskaspelClient:'Externa Systemspelsappen 1.0',
  svenskaspelRetailer: '820299',
  maxAmountWidget: 41472,
  particpantsObj: {},
  schibstedURL: "https://api.sportsnext.schibsted.io/v1",
  abBaseURL: "https://api.sportsnext.schibsted.io/v1/ab/",
  siteUrl: 'https://sportbladet.cortexcraft.com/',
  timeLineImagesObj: {
    "eventEnd": "whistle.svg",
    "offside": "offside.svg",
    "eventStart": "whistle.svg",
    "periodStart": "whistle.svg",
    "shot": "shot.svg",
    "corner": "corner.svg",
    "injury": "injury.svg",
    "penalty": "penaltyawarded.svg",
    "pauseStart": "whistle.svg"
  },
  listOfMenu: [],
  listofTabsMap: {},
  listofMalTableTabs: ["Inbördes möten", "5 senaste", "Händelser", "Lag", " Tabell", "Stats"],
  notStartedMsg: "matchen har inte börjat",
  mapTamplates: {
    "Dagens säkra": "Dagens Spel",
    "Dagens Säkra": "Dagens Spel",
    "Dagens drag": "Dagens Spel",
    "Dagens Drag": "Dagens Spel",
    "Dagens avslag": "Dagens Spel",
    "Dagens Avslag": "Dagens Spel"
  },
  mapRevTemplates: {
    "Dagens Säkra": "dagensakra",
    "Dagens Drag": "dagensdrag",
    "Dagens Avslag": "dagensavslag",
    "Powerplay": "powerplay",
    "Topptipset": "topptipset",
    "Bomben1": "bomben1",
    "Bomben2": "bomben2",
    "Dagens Största Matcher": "dagensstorsta",
    "Stryktipset": "stryktipset",
    "Europatipset": "europatipset"
  },
  mapSports: { "1": "football", "2": "ice-hockey" },
  mapSportSwedish: {
    '1': 'fotboll',
    '2': 'hockey'
  },
  mapSportName: {
    'football': 'fotboll',
    'ice-hockey': 'hockey'
  },
  mapSportEnglish: {
    'fotboll': 'football',
    'hockey': 'ice-hockey'
  },
  mapGameType: { "1": "1X2" },
  mapWidgetTemplate: {
    "stryktipset": "STRYKTIPSET"
  },
  hideGrades: ["dagensakra", "dagensavslag", "single"],
  mapStatsFootball: {
    "possession": "Bollinnehav",
    "corners": "Hörna",
    "fouls": "Frispark",
    "freeKicks": "Frisparkar",
    "goalKicks": "Utspark",
    "offsides": "Offside",
    "shotsOffTarget": "Avslut utanför",
    "shotsOnTarget": 'Avslutning på mål',
    "throwIns": "Inkast",
    "injuries": "Skador",
    "redCards": "Rött kort",
    "shotsSaved": "Skott räddade",
    "yellowCards": "Gult kort",
    "yellowRedCards": "Gult/Rött kort",
  },
  mapStatsHockey: {
    "possession": "Puckinnehav",
    "goals": "Mål",
    "penalties": "Straffar",
    "powerPlays": "Övertalsspel",
    "goalsWhileShortHanded": "Mål i numerärt underläge",
    "goalsInPowerPlay": "Mål i övertalsspel",
    "suspensionsMinutes": "Utvisningsminuter",
    "suspensions": "Utvisningar"
  },
  display: {
    "displayBoth": "both",
    "displayLeft": "left",
    "displayRight": "right",
    "hideRight": "hideRight",
    "hideLeft": "hideLeft",
    "displayNone": "none"
  },
  seoTitle: {
    '/': 'Speltips - De bästa speltipsen med liveodds | Aftonbladet',
    'speltips': 'Speltips - De bästa speltipsen med liveodds | Aftonbladet',
    'dagens-spel': 'Dagens spel - Här hittar dagens säkra och dagens drag | Aftonbladet',
    'stryktipset': 'Stryktipset - 13 rätt, här hittar du det bästa tipset | Aftonbladet',
    'spelnyheter': 'Nyheter som kan påverka ditt spel | Aftonbladet',
    'europatipset': 'Europatipset experternas tips live från | Aftonbladet',
    'topptipset': 'Topptipset bästa tipsen varje dag | Aftonbladet',
    'powerplay': 'Powerplay tips på det största spelet för hockey | Aftonbladet',
    'spelpaus': 'Spelpaus | Aftonbladet',
  },
  metaDescription: {
    '/': 'Sportbladets spelexperter har levererat speltips sedan 1927.Dagliga analyser med odds från ett flertal spelbolag samt bolagsspel. Speltips från sportbladets spelexperter.',
    'speltips': 'Sportbladets spelexperter har levererat speltips sedan 1927.Dagliga analyser med odds från ett flertal spelbolag samt bolagsspel. Speltips från sportbladets spelexperter.',
    'dagens-spel': 'Varje dag tar experterna fram dagens säkra och dagens drag. Dagens säkra är utvalt med lågt odds men hög träffsäkerhet. Dagens drag är ofta över två gånger pengarna. Varje dag tar experterna fram dagens säkra och dagens drag.Dagens säkra är utvalt som dagens säkraste spel av alla matcher, ofta lågt odds men hög träffsäkerhet.Dagens drag är ofta över två gånger pengarna där har våra experter hittat ett överodds eller ett spel med bra värde.',
    'stryktipset': 'Vill du ha 13 rätt på stryktipset? Sportbladets experter hjälper dig med tips för lördagens omgång. Vi har rekat de bästa tipsen sedan 1927.',
    'europatipser': 'Europatipset hämtas från de europeiska ligorna men kan även innehålla landskamper eller matcher från andra delar av världen. Europatipset avgörs oftast med en vardags omgång och en omgång på söndagar.',
    'topptipset': 'Sätt åtta rätt med oss på Topptipset! Topptipset har en ny omgång att spela på nästan varje dag, du behöver bara pricka in 1, X eller 2.',
    'powerplay': 'Powerplay är spelet för hockey, Sportbladets spelexperter ger dig tips på spelet som under säsong kan spelas nästan varje dag.',
    'spelpaus': 'Så här stänger du av dig själv sig från samtliga licensierade spelbolag på bara några minuter. Känner du att du har problem med ditt spelande? Stäng av dig själv.',
    'game': 'Odds och speltips för replace_teams | Aftonbladet - Sportbladet alltid de aktuella oddsen och de bästa speltipsen.'
  },
  articleSeoTitle: {
    'stryktipset': 'Bästa tipset för Stryktipset lördagen date_replace | Aftonbladet',
    'powerplay': 'Powerplay idag date_replace | Aftonbladet',
    'spelpaus': 'Stäng av dig själv från spel med spelpaus | Aftonbladet'
  },
  seoTemplateArray: ['spelnyheter', 'europtipset', 'topptipset',],
  seoHeaderTag: {
    'speltips': 'Speltips från sportbladets spelexperter.',
    '/': 'Speltips från sportbladets spelexperter.',
    'dagens-spel': 'Dagens spel presenterat av Sportbladet',
    'stryktipset': 'Stryktipset med tips från sportbladets spelexperter.',
    'europatipset': 'Sporbladets spelexperter levererar det bästa Europatipset.',
    'topptipset': 'Tips varje dag med Topptipset',
    'powerplay': 'Powerplay speltips för hockey',
  },
  widgetTab:['64','864','1024','chans1428'],
  widgetCityMap: {
    "malmo":"Malmö",
    "uppsala":"Uppsala",
    "/malmo":"Malmö",
    "/uppsala":"Uppsala",
  },
  FINISHED: "finished",
  NOT_STARTED : "notStarted",
}

export default config;
