import config from "../config";
import helpers from "./helpers";
import axios from 'axios';
import dayjs from 'dayjs'

var apicall = {
    fetchMore: true,
    fetchArticles: async function (type) {
        var lastPage = 0;
        var listofArticles = [];
        await config.localforage.getItem('articles', (err, articles) => {
            if (typeof articles !== "undefined" && articles !== null) {
                listofArticles = articles;
                lastPage = parseInt(articles.length / 5);
            }
        });
        if (this.fetchMore) {
            var articles = [];
            var page = lastPage + 1;
            await axios.get(config.baseURL + "v1/articles/collect.json?tag=Speltips&page=" + page,
            {
                validateStatus: function (status) {
                    return status < 400
                }
            }).then(response => {
                    articles = response.data.articles;
                    if (articles.length > 0) {
                        listofArticles.push(...articles);
                    }
                    if (articles.length < 5) {
                        this.fetchMore = false;
                    }
                    config.localforage.setItem('articles', listofArticles, function (err) {
                    });
                    config.localforage.setItem('articlesfetchDate', new Date(), function (err) {
                    })

                }).catch(function (error) {
                    console.log("A error Occured while Fetching List of Articles");
                });
        }
        return listofArticles;
    },
    fetchFilteredArticles: async function (tabIndex) {
        var lastPage = 0;
        var listofArticles = [];
        var showWidget = true;
        await config.localforage.getItem('articles', (err, articles) => {
            if (typeof articles !== "undefined" && articles !== null) {
                listofArticles = articles;
                lastPage = parseInt(articles.length / 5);
            }
        });
        if (this.fetchMore) {
            var requestObj = {};
            var listofTabsMap = config.listofTabsMap;
            var tabsKeyArray = Object.keys(listofTabsMap);
            requestObj["template"] = config.listofTabsMap[tabsKeyArray[tabIndex - 1]];
            requestObj["page"] = lastPage + 1;
            let query = Object.keys(requestObj)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(requestObj[k]))
                .join('&');
            var articles = [];
            console.log(query);
            await axios.get(config.baseURL + "v1/articles/filter?" + query,
            {
                validateStatus: function (status) {
                    return status < 400
                }
            }).then(response => {
                    articles = response.data.articles;
                    showWidget = response.data.showWidget;
                    if (articles.length > 0) {
                        listofArticles.push(...articles);
                    }
                    if (articles.length < 5) {
                        this.fetchMore = false;
                    }
                    config.localforage.setItem('articles', listofArticles, function (err) {
                    });
                    config.localforage.setItem('articlesfetchDate', new Date(), function (err) {
                    })

                }).catch(function (error) {
                    console.log("A error Occured while Fetching List of Articles");
                });
        }
        return { articles: listofArticles, showWidget: showWidget };
    },
    fetchArticleById: async function (id) {
        let article
        await axios.get(config.baseURL + "v1/articles/" + id + "?optimized=true",
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(response => {
                article = response.data;
            }).catch(function (error) {
                console.log("A error Occured while Fetching Article ID");
            });
        return article;
    },
    getTabs: async function () {
        var tabsList = [];
        await axios.get(config.baseURL + "v1/articles/types?improved=true",{
            validateStatus: function (status) {
                return status < 400
            }
        }).then(resp => {
                tabsList = resp.data;
            });
        return tabsList;
    },
    searchArticles: async function (searchText) {
        var articles = []
        var searchObj = {
            "tag": "Speltips"
        }
        if (searchText !== null && typeof (searchText) !== "undefined") {
            await axios.get(config.baseURL + "v1/articles/search?q=" + searchText,
            {
                validateStatus: function (status) {
                    return status < 400
                }
            }).then(response => {
                    articles = response.data.articles;
                    var windowLocation = window.location.href;
                    config.localforage.setItem('articles', articles, function (err) {
                        console.log(err);
                    });

                }).catch(function (error) {
                    console.log("A error Occured while Searching Articles");
                });
        }
        return articles;
    },
    searchArticlesAndEvents: async function (searchText) {
        console.log('came here')
        var searchResult = { articles: [], past_events: [], upcoming_events: [] }
        var searchObj = {
            "tag": "Speltips"
        }
        if (searchText !== null && typeof (searchText) !== "undefined" && searchText.length > 0) {
            await axios.get(config.baseURL + "v1/search?q=" + searchText, {
                validateStatus: function (status) {
                    return status < 400
                }
            })
                .then(searchResultJson => {
                    console.log('search-->',searchResultJson)
                    searchResult = searchResultJson.data;
                }).catch(function (error) {
                    console.log("A error Occured while Searching Articles");
                });
        }
        return searchResult;
    },
    fetchMalDetails: async function (participantId, opponentId) {
        var malDetails = null;
        let params = {};
        params["limit"] = 5;
        if (typeof opponentId !== "undefined" && opponentId !== 0) {
            params["opponentId"] = opponentId;
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');

        await axios.get(config.schibstedURL + "/ab/participants/" + participantId + "/last-events?" + query,
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(jsondata => {
                malDetails = jsondata.data;
            }).catch(function (error) {
                console.log("error while fetching events")
            });
        return malDetails;
    },
    eventReport: async function (eventId) {
        var reports = {};
        await axios.get(config.abBaseURL + "events/" + eventId + "/report",
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(reportsjson => {
                reports = reportsjson.data;
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return reports;
    },

    fetchEvent: async function (eventId) {
        var eventDetails = null;
        await axios.get(config.schibstedURL + "/ab/events/" + eventId,
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(eventjson => {
                eventDetails = eventjson.data;
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return eventDetails;
    },

    fetchEvents: async function (eventId) {
        var eventDetails = null;
        await axios.get(config.baseURL + "/v1/events/collect?event_ids=" + eventId,
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(eventjson => {
                eventDetails = eventjson.data;
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return eventDetails;
    },

    tournamentStandings: async function (seasonId, stageName) {
        var standings = null;
        var query = null;
        if (typeof stageName !== 'undefined' && stageName !== null &&
            stageName !== '' && stageName.length < 3) {
            query = "/standings?type=standard&scope=total&stageName=" + stageName;
        } else {
            query = "/standings?type=standard&scope=total";
        }
        await axios.get(config.schibstedURL + "/ab/tournaments/seasons/" + seasonId + query,
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(standingsjson => {
                standings = standingsjson.data;
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return standings;
    },

    schedules: async function (sportName, date) {
        var schedules = {};
        await axios.get(config.abBaseURL + "schedule?date=" + date + "&sport=" + sportName,
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(schedulesjson => {
                schedules = schedulesjson.data;
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return schedules;
    },

    fetchEventsByDate: async function (date, zoneDiff, game) {
        var events = {};
        var query = null;
        if (game !== null && typeof game !== "undefined") {
            query = "v1/events?" + "date=" + date + "&zoneDiff=" + zoneDiff + "&game=" + game;
        } else {
            query = "v1/events?" + "date=" + date + "&zoneDiff=" + zoneDiff;
        }
        await axios.get(config.baseURL + query,
            {
                validateStatus: function (status) {
                    return status < 400
                }
            })
            .then(eventsjson => {
                events = eventsjson.data;
                var eventsdate = new Date();
                events["date"] = eventsdate;
                config.localforage.setItem('todaysGames', events, function (err) {
                });
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return events;
    },
    getEvents: async function (widgetCity,total) {
        var events = {};
        let url = 'https://ab-affiliate.dunderdog.se/api/v1/events_widget.json?q='+widgetCity;
        if (total) {
            url = url + '&total=100'
        }
        await axios.get(url,{
            validateStatus: function (status) {
                return status < 400
            }
        }).then(eventsjson => {
                events = eventsjson.data;
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return events;
    },
    fetchWidgetArticles: async function (widgetCity) {
        var lastPage = 0;
        var listofArticles = [];
        await config.localforage.getItem('articles', (err, articles) => {
            if (typeof articles !== "undefined" && articles !== null) {
                listofArticles = articles;
                lastPage = parseInt(articles.length / 5);
            }
        });
        if (this.fetchMore) {
            var requestObj = {};
            requestObj["q"] = widgetCity;
            requestObj["page"] = lastPage + 1;
            let query = Object.keys(requestObj)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(requestObj[k]))
                .join('&');
            var articles = [];
            await axios.get(config.baseURL + "v1/articles/filtered_articles?" + query,{
                validateStatus: function (status) {
                    return status < 400
                }
            }).then(articlesjson => {
                    articles = articlesjson.data.articles;
                    if (articles.length > 0) {
                        listofArticles.push(...articles);
                    }
                    if (articles.length < 5) {
                        this.fetchMore = false;
                    }
                    config.localforage.setItem('articles', listofArticles, function (err) {
                    });
                    config.localforage.setItem('articlesfetchDate', new Date(), function (err) {
                    })

                }).catch(function (error) {
                    console.log("A error Occured while Fetching List of Articles");
                });
        }
        return listofArticles;
    },
    fetchSuggestions: async function (template) {
        var expertSuggestions = null;
        await axios.get(config.baseURL + "v1/expert_suggestions?product_name=" + helpers.capitalizeFirstLetter(template.toLowerCase()),
        {
            validateStatus: function (status) {
                return status < 400
            }
        }).then(json => {
                expertSuggestions = json.data;
            }).catch(function (error) {
                console.log("A error Occured while Searching Articles");
            });
        return expertSuggestions;
    },
    registerClick: async function (clickObject) {
        var clickResponse = null;
        var requestObject = clickObject;
        await axios.post(config.affiliateBaseURL + "api/v1/register_click",
            requestObject,{
                validateStatus: function (status) {
                    return status < 400
                }
            }).then(
                resp => {
                    console.log(resp.data)
                    clickResponse = resp;
                }
            ).catch(function (error) {
                if (error.response) {
                    console.log(error.response.data)
                }
            }
            )
        return clickResponse;
    },
    getCalenderEvents: async function () {
        const URL = "https://event-widget.cortexcraft.com/events/calender.json";
        let calenderEvents
        await axios.get(URL,{
            validateStatus: function (status) {
                return status < 400
            }
        }).then(
            resp => {
                calenderEvents = resp.data
            }
        ).catch(err => {
            console.log('error')
        })
        return calenderEvents
    },
    filterCalenderEvents: async function (category, date) {
        let url = "https://event-widget.cortexcraft.com/events/calender.json?city=Malmo"
        if (date)
            url = `${url}&date=${dayjs(date).format('YYYY-MM-DD')}`
        if (category)
            url = `${url}&category=${category}`
        let calenderEvents
        await axios.get(url,{
            validateStatus: function (status) {
                return status < 400
            }
        }).then(
            resp => {
                calenderEvents = resp.data
            }
        ).catch(err => {
            console.log('error')
        })
        return calenderEvents
    }

};

export default apicall;