import React, { Component } from "react";
import CompletedEvents from './CompletedEvents';
import LiveEvents from './LiveEvents';
import UpcomingEvents from './UpcomingEvents';
import { Link } from "react-router-dom";
import pym from 'pym.js';
export default class WidgetComponent extends Component {

    constructor(props){
        super(props)
        this.state={
            apicallSuccess : false
        }
    }

    apicallStatus = () => {
        this.setState({
            apicallSuccess: true
        })
    }

    render() {
        if(this.state.apicallSuccess){
            var pymChild = new pym.Child({ id: 'iframe_widget'});
        }
        let widgetCity 
        if(this.props.match.path === "/widget-events")
            widgetCity = "malmo"
        else if(this.props.match.path === "/widget-events-uppsala")
            widgetCity = "uppsala"
        return (
            <div id="iframe_widget">
                <aside className="widget__in-iframe">
                    <div className="sidebar-component">
                        <LiveEvents widgetCity={widgetCity} />
                        <UpcomingEvents widgetCity={widgetCity}/>
                        <CompletedEvents apicallStatus={this.apicallStatus} widgetCity={widgetCity}/>
                        <Link to={widgetCity === "malmo" ?  "/malmo" : "uppsala"} target="_top" className="btn btn-show-more">
                            VISA FLER
                        </Link>
                    </div>
                </aside>
            </div>
        )
    }
}