import React from 'react';
import { withRouter } from 'react-router-dom';
import config from '../config';
class RedirectComponent extends React.Component {
    componentDidUpdate(prevProps) {
        let pathname = this.props.location.pathname;
        let windowLocation = window.location.href;
        if (pathname !== "/" && pathname.slice(-1) === "/") {
            let newpath = pathname.slice(0, -1)
            this.props.history.push(newpath);
        }
        else if (pathname == "/" && windowLocation.slice(-1) === "/") {
            window.location.replace(config.baseUrlName);
        }
    }

    render() {
        return this.props.children
    }
}

export default withRouter(RedirectComponent) 