import React, { Component } from "react";

export default class GoalEventComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants
        }
    }

    render() {
        return (<div className="report-items-list-item">
            <div className="report-item report-item-scoreChange report-item-goal report-item-football">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/goal-football.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-header">Mål för {this.state.participants.team}!</div>
                    <div className="report-item-content">
                        <div className="report-item-scorechange-content">
                            <div className="report-item-scorechange-info">
                                <div className="report-item-scorechange-current-scores">{this.props.currentScores['0'][0]} {this.props.currentScores['0'][1]} - {this.props.currentScores['1'][1]} {this.props.currentScores['1'][0]}</div>
                                <div className={this.state.participants.player !== undefined ? "report-item-scorechange-scorer" : "hide"}>Mål: {this.state.participants.player}</div>
                                <div className={this.state.participants.assist !== undefined ? "report-item-scorechange-scorer" : "hide"}>Assist: {this.state.participants.assist}</div>
                            </div>
                        </div>
                    </div>
                    <div className="report-item-comment">{this.state.report.text}</div>
                </div>
                <div className="report-item-time">{this.props.getElapsed}</div>
            </div>
        </div>);
    }
}