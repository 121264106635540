import React, { Component } from "react";
export default class MobileNavbar extends Component {
    render() {
        return (
            <div className="mobileNavbar">
                <ul className="aside navbar-nav">
                    <li className="nav-item"><a href="#" className="nav-link"><svg aria-hidden="true" data-prefix="fas" data-icon="align-justify"
                        className="svg-inline--fa fa-align-justify fa-w-14 fa-2x " role="img" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512">
                        <path fill="currentColor" d="M0 84V44c0-8.837 7.163-16 16-16h416c8.837 0 16 7.163 16 16v40c0 8.837-7.163 16-16 16H16c-8.837 0-16-7.163-16-16zm16 144h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 256h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0-128h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
                    </svg><span className="pl-2 pr-2 navspantext">Feed </span></a></li>
                    <li className="nav-item"><a href="#" className="nav-link"><svg aria-hidden="true" data-prefix="fas" data-icon="calendar"
                        className="svg-inline--fa fa-calendar fa-w-14 fa-2x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z"></path>
                    </svg><span className="pl-2 pr-2 navspantext"> När</span></a></li>
                    <li className="nav-item"><a href="#" className="nav-link"><svg aria-hidden="true" data-prefix="fas" data-icon="circle"
                        className="svg-inline--fa fa-circle fa-w-16 fa-2x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                    </svg><span className="pl-2 pr-2 navspantext"> Betta</span></a></li>
                    <li className="nav-item"><a href="#" className="nav-link"><svg aria-hidden="true" data-prefix="fas" data-icon="gift"
                        className="svg-inline--fa fa-gift fa-w-16 fa-2x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor" d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm448-288h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40zm-72 320h160c17.7 0 32-14.3 32-32V320H288v160z"></path>
                    </svg><span className="pl-2 pr-2 navspantext"> Bonus</span></a></li>
                    <li className="dropdown nav-item"><a aria-haspopup="true" href="#" className="dropdown-toggle nav-link" aria-expanded="false"><svg
                        aria-hidden="true" data-prefix="fas" data-icon="ellipsis-v" className="svg-inline--fa fa-ellipsis-v fa-w-6 fa-2x "
                        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                        <path fill="currentColor" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"></path>
                    </svg><span className="pl-2 pr-2 navspantext"> Mer</span></a>
                        <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right"><button type="button"
                            tabIndex="0" className="dropdown-item">Option 1</button><button type="button" tabIndex="0" className="dropdown-item">Option
                    2</button>
                            <div tabIndex="-1" className="dropdown-divider"></div><button type="button" tabIndex="0" className="dropdown-item">Reset</button>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}