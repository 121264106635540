import React from 'react'
import grade_img from "../../images/grade.svg";

const constructImage = (teamGrade) => {
    if (teamGrade !== null && typeof teamGrade !== "undefined") {
        var grades = teamGrade.length;
        var gradesImg = [];
        for (let grade of grades) {
            gradesImg.push(
                <img className="grade-img" src={grade_img} alt="+"></img>
            )
        }
        return gradesImg;
    }
}

export default function ShowGrades(props) {
    const {team1Grade, team2Grade} = props;
    let gameValue = props.gameValue;
    gameValue = (gameValue || '').toString().toUpperCase()
    return (
        <div className="event-grades">
            <div className="team-grade team-grade-1">
                {constructImage(team1Grade)}
            </div>
            <div className="game-suggestions">
                <div className={"game-suggestion " + (gameValue.includes("1") ? "add-background" : "")}>
                    1
                </div>
                <div className={"game-suggestion " + ((gameValue.includes("X") || gameValue.includes("x")) ? "add-background" : "")}>
                    X
                </div>
                <div className={"game-suggestion " + (gameValue.includes("2") ? "add-background" : "")}>
                    2
                </div>
            </div>
            <div className="team-grade team-grade-2">
                {constructImage(team2Grade)}
            </div>
        </div >
    )
}
