import React, { Component } from "react";
import EventsListComponent from './EventsListComponent';
export default class UpcomingEvents extends Component {
    render() {
        console.log(this.props)
        return (
            <EventsListComponent eventType='upcoming' heading='Kommande'
                className="events-section events-section__upcoming" malmo={this.props.malmo} 
                widgetCity={this.props.widgetCity}/>

        )
    }
}
