import React, { Component } from "react";

export default class ScoreChangeGoalComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants,
            txt: this.props.txt
        }
    }

    render() {
        return (<li className="report-items-list-item">
            <div className="report-item report-item-scoreChange report-item-goal report-item-ice-hockey">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/goal-ice-hockey.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-header">Mål för {this.state.participants.team}!</div>
                    <div className="report-item-content">
                        <div className="report-item-scorechange-content">
                            <div className="report-item-scorechange-info">
                                <div className="report-item-scorechange-current-scores">{this.props.currentScores['0'][0]} {this.props.currentScores['0'][1]} - {this.props.currentScores['1'][1]} {this.props.currentScores['1'][0]}</div>
                                <div className="report-item-scorechange-scorer">Mål: {this.state.participants.goalScorer}</div>
                                <div className="report-item-scorechange-assists">Assist: {this.state.participants.assist}, {this.state.participants.assist2}</div>
                            </div>
                            <div className="goal-section goal-section-large">
                                <div className={"goal-section-marker goal-section-marker-" + this.state.report.goalSection}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="report-item-time">{this.props.getElapsed}</div>
            </div>
        </li>);
    }
}