import React, { Component } from "react";
import config from '../config';
import { Collapse } from 'reactstrap';
import TabsComponent from "./TabsComponent";
import StatisticsComponent from "./StatisticsComponent";
import TimeLineMainComponent from "./malservice/TimeLineMainComponent";
import LineupComponent from "./LineupComponent";
import PointsTableComponent from "./PointsTableComponent";
import TournamentPastScoreComponent from "./malservice/TournamentPastScoreComponent";
import DisplayEventInfoComponent from "./DisplayEventInfoComponent";
import DisplayEvent from "./DisplayEvent";
import teamBlankLogo from "../images/team-blank-logo.svg"
import event_football from "../images/event-football.jpg";
import event_hockey from "../images/event-hockey.jpg";
import dayjs from 'dayjs'
import author_avatar from "../images/avatar-author.png";
import apicall from "../utils/apicall";
import _ from "lodash";
import Helmet from "react-helmet";
import helpers from "../utils/helpers";
import InbordesMoten from "./malservice/InbordesMoten";
import FiveSenaste from "./malservice/FiveSenaste";
export default class EventDetailsPageComponent extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            event: this.props.event,
            pastEventsArray: null,
            participantsData: {},
            tournamentsArray: [],
            matchParticipants: {},
            article: this.props.article,
            collapse: false,
            articlePage: false,
            teamA: null,
            teamB: null,
            teamAComment: null,
            teamBComment: null,
            comment: null,
            articleGame: null,
            gameCount: this.props.gameCount,
            schibsted_events: null,
            lineupsData: null,
            lineupStatusCode: null,
            expertdetails: null,
            previousMatches: {}
        }
        this.state = {
            ...this.state,
            ...this.props
        }
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    componentWillMount() {
        this.fetchLineUp();
        this.fetchPreviousMatches();
    }

    fetchLineUp = () => {
        var eventId = this.props.event.eventid;
        var status = '';
        fetch(config.schibstedURL + "/ab/events/" + eventId + "/lineups", {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(
            response => response.json())
            .then(jsondata => {
                var lineupsData = jsondata;
                this.setState({
                    lineupsData: lineupsData,
                    lineupStatusCode: jsondata.statusCode
                });
            }).catch(function (error) {
                console.log("error while fetching events")
            });
    }

    fetchPreviousMatches = () => {
        var participantId = this.state.participantsData.team1;
        var opponentId = this.state.participantsData.team2;
        apicall.fetchMalDetails(participantId, opponentId).then(
            jsondata => {
                this.setState({ previousMatches: jsondata })
            }
        );
    }

    displayContent() {
        var homeOwn = null;
        var event = this.state.event;
        if (event.status.type == "finished" && event.winners.winnerId) {
            homeOwn = event.teams[0].id == event.winners.winnerId;
        }
        var time = dayjs(event.start_date).format('HH:mm');
        var team_info = event.team_info;
        var team_info_keys = Object.keys(team_info);
        var team1Name = "";
        var team2Name = "";
        var team1Img = "";
        var team2Img = "";
        var team1Id = "";
        var team2Id = "";
        if (team_info_keys.length !== 0) {
            for (var i = 0; i < team_info_keys.length; i++) {
                if (team_info[team_info_keys[i]].position === "home") {
                    team1Name = team_info[team_info_keys[i]].name;
                    team1Img = team_info[team_info_keys[i]].logo;
                    team1Img = team1Img ? (team1Img + "?rule=clip-32x32") : teamBlankLogo;
                    team1Id = team_info_keys[i];
                }
                if (team_info[team_info_keys[i]].position === "away") {
                    team2Name = team_info[team_info_keys[i]].name;
                    team2Img = team_info[team_info_keys[i]].logo;
                    team2Img = team2Img ? (team2Img + "?rule=clip-32x32") : teamBlankLogo;
                    team2Id = team_info_keys[i];
                }
            }
        }
        if (typeof event.results[team1Id] !== "undefined") {
            var score1 = event.results[team1Id] && event.results[team1Id].runningScore;
            var score2 = event.results[team2Id] && event.results[team2Id].runningScore;
        }
        return (
            <div className="teaser-match-heading">
                <div className="team team-left">
                    <div className={"team-name " + ((homeOwn != null && homeOwn) ? 'game-won' : '')}>{team1Name}</div>
                    <img src={team1Img} className="team-logo team-logo__right" alt="" />
                </div>
                {event.status === 'notStarted' ?
                    (<div className="time">{time}</div>) :
                    (<div className="time">{score1} - {score2}</div>)
                }
                <div className="team">
                    <img src={team2Img} className="team-logo team-logo__left" alt="" />
                    <div className={"team-name " + ((homeOwn != null && homeOwn) ? 'game-won' : '')}>{team2Name}</div>
                </div>
            </div>
        );
    }

    displayodds() {
        if (this.state.event) {
            var event = this.state.event;
            if (event.odds !== null && typeof event.odds !== 'undefined') {
                var odds = event.odds;
                var oddskey = Object.keys(odds);
                if (oddskey.length !== 0) {
                    var res = [];
                    for (let oddComp in odds) {
                        res.push(
                            <div className="teaser-match-odds" key={event.eventid}>
                                <div className="column teaser-match-odds-heading">ODDS</div>
                                <div className="column">
                                    <div className="teaser-match-odds-odds">{odds[oddComp][1].value && odds[oddComp][1].value.toFixed(2)}</div>
                                    <div className="teaser-match-odds-odds">{odds[oddComp]['X'].value && odds[oddComp]['X'].value.toFixed(2)}</div>
                                    <div className="teaser-match-odds-odds">{odds[oddComp][2].value && odds[oddComp][2].value.toFixed(2)}</div>
                                </div>
                            </div>
                        )
                    }
                    return res;
                } else {
                    return null;
                }
            }
        }
    }

    componentWillReceiveProps(newprops) {
        if (newprops !== this.props) {
            if (newprops.event.eventid !== this.props.event.eventid || newprops.article !== this.state.article) {
                this.setState({
                    ...this.state,
                    ...newprops
                }, this.fetchLineUp);
            }
        }
    }

    fetchEventDetails = () => {
        var selectedGame = null;
        var expert_details = null;
        let googleTitle = null;
        if (typeof this.state.article !== 'undefined' && this.state.article !== null && Object.keys(this.state.article).length > 0) {
            googleTitle = !_.isEmpty(this.state.article.custom_properties.googleTitle) ?  this.state.article.custom_properties.googleTitle : this.state.article.title;
            var article = this.state.article;
            var expert_details = article.expert_details || {};
            var description = "";
            var spelMatch = [];
            if (!(article.description instanceof Array)) {
                article.description = article.description.replace(/=>/g, ':');
                description = JSON.parse(article.description);
            }
            else {
                description = article.description;
            }

            for (var i = 0; i < description.length; i++) {
                if (typeof description[i] !== "undefined" && description[i] !== null) {
                    if (typeof description[i].type !== "undefined" &&
                        description[i].type === "deprecated-integration") {
                        spelMatch.push(description[i]);
                    }
                }
            }

            var teams = this.state.event.team_info;
            var teamKeys = Object.keys(teams);
            var teamAId = teams && teamKeys[0];
            var teamBId = teams && teamKeys[1];
            for (let desc of spelMatch) {
                var url = desc.url;
                for (let game of url.games) {
                    if (typeof this.state.gameCount !== "undefined" && this.state.gameCount !== null
                        && this.state.gameCount !== "null") {
                        if (game["game"] === parseInt(this.state.gameCount)) {
                            selectedGame = game;
                            break;
                        }
                    } else if (game["eventid"] === this.state.event.eventid &&
                        ((game["team_a_participant_id"] === teamAId && game["team_b_participant_id"] === teamBId) || (game["team_b_participant_id"] === teamAId && game["team_a_participant_id"] === teamBId))) {
                        selectedGame = game;
                        break;
                    }
                }
            }
        }
        return [selectedGame, expert_details,googleTitle];
    }

    fetchVenueDeatils = () => {
        if (this.state.event !== null && typeof this.state.event !== 'undefined') {
            var event = this.state.event;
            var venueDetails = event.details;
            var time = dayjs(event.startDate).format('HH:mm');
            return (
                <div className="venue-details">
                    <div className="stadium">
                        {venueDetails.hasOwnProperty("venue") ? venueDetails.venue.name : null}
                    </div>
                    <div className="time">
                        {time}
                    </div>
                </div>
            )
        }
    }

    render() {
        var details = this.fetchEventDetails();
        var selectedGame = details[0];
        var expert_details = details[1] || {};
        console.log('expert_details',expert_details)
        const googleTitle = details [2];
        var listofMalTableTabs = config.listofMalTableTabs;
        var listofTableTabs = [];
        var eventScoreDiv = new Array(listofMalTableTabs.length).fill(null);
        var participantsData = this.state.participantsData;
        var tournamentDiv = new Array(3).fill(null);
        // console.log("this.state.event ", this.state.event )
        const isManualGame = _.has(this.state.event,'manual') && this.state.event.manual == true ? true : false;
        if (!_.isEmpty(participantsData) && this.state.event && !isManualGame ) {
            var tournamentsArray = this.state.tournamentsArray;

            var gameStatus = this.state.event.status;
            // if(gameStatus !== "finished"){
            //     listofMalTableTabs = listofMalTableTabs.slice(0,2);
            // }
            var count = 0;
            if (this.state.collapse && this.state.previousMatches.events && this.state.previousMatches.events.length > 0) {
                eventScoreDiv[count++] = <InbordesMoten 
                participantsData={participantsData} previousMatches={this.state.previousMatches}/>
                listofTableTabs.push("Inbördes möten")
            }
            eventScoreDiv[count++] = <FiveSenaste participantsData={participantsData} displayIndividualScores={true} />
            listofTableTabs.push("5 senaste");
            if (gameStatus === 'finished') {
                eventScoreDiv[count++] = <TimeLineMainComponent eventId={this.state.event.eventid} />
                listofTableTabs.push("Händelser");
            }
            if (this.state.lineupStatusCode !== 404) {
                eventScoreDiv[count++] = <LineupComponent generateTabURL={false} eventId={this.state.event.id} lineupsData={this.state.lineupsData} />
                listofTableTabs.push("Lag");
            }
            eventScoreDiv[count++] = <PointsTableComponent eventId={this.state.event.eventid} participantsData={participantsData} />
            listofTableTabs.push("Tabell");
            if (gameStatus === 'finished') {
                eventScoreDiv[count++] = <StatisticsComponent eventId={this.state.event.eventid} />
                listofTableTabs.push("Stats");
            }
        }
        // else {
        //     return null;
        // }

        var commentsDiv = null;
        if (selectedGame !== null) {
            console.log('selectedGame',selectedGame)
            var comments = [];
            var comment = selectedGame["game-comment"];
            var teamAComment = selectedGame["team-a-comment"];
            var teamBComment = selectedGame["team-b-comment"];
            if (comment && comment.toString().length > 0) {
                comments.push(<><p><span className="icon icon-rhombus icon-rhombus__blue"></span> {comment}</p><p>{teamAComment}</p>
                    <p>{teamBComment}</p></>);
            } else if (teamAComment && teamAComment.toString().length > 0) {
                comments.push(<><p><span className="icon icon-rhombus icon-rhombus__blue"></span> {teamAComment}</p>
                    <p>{teamBComment}</p></>);
            } else if (teamBComment && teamBComment.toString().length > 0) {
                comments.push(<><p><span className="icon icon-rhombus icon-rhombus__blue"></span> {teamBComment}</p></>);
            }
            var expert_details_keys = Object.keys(expert_details);
            var expert = expert_details[expert_details_keys[0]] || {};
            commentsDiv =
                <div style={{ width: '100%' }}>
                    <div className="teaser-match-content">
                        <div className="teaser-match-content-editor">
                            <img src={expert.hasOwnProperty('image_url') &&
                                expert.image_url !== "" ? expert.image_url : author_avatar} alt="" />
                            <div className="icon icon-heart icon-white"></div>
                            <div className="teaser-match-content-editor-name">
                                {expert.hasOwnProperty('name') ? expert.name : ""}
                            </div>
                        </div>
                        <ul className="list teaser-match-content-editor-tips">
                            <li className="icon icon-rhombus icon-rhombus__blue">
                                <span>Sport: </span> <span>{_.upperFirst(this.state.event.sport) + " / " + selectedGame["league"]}</span>
                            </li>
                            <li className="icon icon-rhombus icon-rhombus__blue">
                                <span>Speltyp: </span> <span>{selectedGame["type"] ? selectedGame["type"] : "1x2"}</span>
                            </li>
                            <li className="icon icon-rhombus icon-rhombus__blue">
                                <span>Spelförslag: </span> <span>{selectedGame["value"]}</span>
                            </li>
                            <li className={selectedGame.hasOwnProperty("betting-odds") ? "icon icon-rhombus icon-rhombus__blue" : "hide"}>
                                <span>Odds: </span> <span>{selectedGame["betting-odds"]}</span>
                            </li>
                            <li className={selectedGame.hasOwnProperty("betting-link") ? "icon icon-rhombus icon-rhombus__blue" : "hide"}>
                                <span>Bolag: </span> <span><a target="blank" href={"//" + selectedGame["betting-link"]}>{selectedGame["betting-partner"]}</a></span>
                            </li>
                        </ul>
                    </div>
                    <div className="teaser-match-content-text">
                        {comments}
                    </div>
                    {helpers.removeMetaTags()}
                    <Helmet>
                        <title>{googleTitle}</title>
                        <meta property="og:title" content={googleTitle} />
                    </Helmet>
                </div>
        }
        if (this.state.isSearch) {
            var sportImage = "";
            if (this.state.sportName) {
                if (this.state.sportName === "football")
                    sportImage = event_football;
                else
                    sportImage = event_hockey;
            }
            return (
                <article className="odds">
                    {/* <div className="article-img">
                        <img src={sportImage}></img>
                    </div> */}
                    {/* {this.fetchVenueDeatils()} */}
                    {this.displayContent()}
                    {this.state.event.status === 'notStarted' ? this.displayodds() : ''}
                    <div>
                        <div className="teaser-match-content">
                            {commentsDiv}
                        </div>
                    </div>
                    <div className="match-stats">
                        {/* <TabsComponent theme='tabbar' components={eventScoreDiv} listofTabs={listofTableTabs} generateTabURL={false} activeTab={1} /> */}
                    </div>
                </article>
            )
        } else {
            return (
                <li className="event-list-item">
                    <div className="schedule-event-container finished participant-home-won">
                        <div className="schedule-event" onClick={this.toggle}>
                            {this.displayContent(selectedGame)}
                        </div>
                    </div>
                    <Collapse isOpen={this.state.collapse}>
                        <DisplayEvent eventId={this.state.event.id} />
                        <DisplayEventInfoComponent eventId={this.state.event.id} />
                        <div className="col-sm-12">
                            {/* <TabsComponent components={eventScoreDiv} listofTabs={listofTableTabs} generateTabURL={false} theme='tabbar' /> */}
                        </div>
                    </Collapse>
                </li>
            );
        }
    }
}