import React, { Component } from "react";
import EndEventComponent from './footbal/EndEventComponent';
import StartEventComponent from './footbal/StartEventComponent';
import YellowCardEventComponent from './footbal/YellowCardEventComponent';
import FreeKickEventComponent from './footbal/FreeKickEventComponent';
import PeriodEventComponent from './footbal/PeriodEventComponent';
import GoalEventComponent from './footbal/GoalEventComponent';
import SubstitutionEventComponent from './footbal/SubstitutionEventComponent';
import RedCardEventComponent from './footbal/RedCardEventComponent';
import TimeLineComponent from "./footbal/TimelineComponent";
import ShotShotSavedComponent from "./footbal/ShotShotSavedComponent";
import ShotShotOnComponent from "./footbal/ShotShotOnComponent";
import ShotShotOffComponent from "./footbal/ShotShotOffComponent";
import CornerCornerComponent from "./footbal/CornerCornerComponent";
import OffsideOffsideComponent from "./footbal/OffsideOffsideComponent";
import InjuryInjuryComponent from "./footbal/InjuryInjuryComponent";
import InjuryInjuryReturnComponent from "./footbal/InjuryInjuryReturnComponent";
import PauseStartComponent from "./footbal/PauseStartComponent";

export default class FootballEventComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants,
            currentScores: this.props.currentScores
        }
    }

    getElapsed() {
        let res = [];
        let elapsed = this.state.report.elapsed;
        if (elapsed.minutes) {
            res.push(elapsed.minutes);
            if (elapsed.minutesPlus) {
                res.push('+');
                res.push(elapsed.minutesPlus);
            }
            res.push("'");
        }
        return res;
    }

    getEventForDisplay() {
        var report = this.state.report;
        var participants = this.state.participants;
        var currentScores = this.state.currentScores;
        switch (report.type) {
            case "eventEnd": {
                return <EndEventComponent report={report} getElapsed={this.getElapsed()} />;
            }
            case "eventStart": {
                return <StartEventComponent report={report} getElapsed={this.getElapsed()} />;
            }
            case "card": {
                if (report.subtype == 'yellow') {
                    return <YellowCardEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                } else if (report.subtype == 'red') {
                    return <RedCardEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "scoreChange": {
                return <GoalEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} currentScores={currentScores} />;
            }
            case "substitution": {
                return <SubstitutionEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
            }
            case "freeKick": {
                if (report.subtype === "freeKick" && this.props.allEvents) {
                    return <FreeKickEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "shot": {
                if (report.subtype === "shotOff" && this.props.allEvents) {
                    return <ShotShotOffComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                } else if (report.subtype === "shotSaved" && this.props.allEvents) {
                    return <ShotShotSavedComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                } else if (report.subtype === "shotOn" && this.props.allEvents) {
                    return <ShotShotOnComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "corner": {
                if (report.subtype === "corner" && this.props.allEvents) {
                    return <CornerCornerComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "periodStart": {
                if (report.subtype === "period1") {
                    return <PeriodEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Första halvlek" />;
                } else if (report.subtype === "period2") {
                    return <PeriodEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Andra halvlek" />;
                } else if (report.subtype === "overtimePeriod1" || report.subtype === "overtimePeriod2") {
                    return <PeriodEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Förlängning" />;
                }
            }
            case "offside": {
                if (report.subtype === "offside" && this.props.allEvents) {
                    return <OffsideOffsideComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "injury": {
                if (report.subtype === "injury" && this.props.allEvents) {
                    return <InjuryInjuryComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                } else if (report.subtype === "injuryReturn" && this.props.allEvents) {
                    return <InjuryInjuryReturnComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
            }
            case "pauseStart": {
                if (report.subtype === "pauseAfterPeriod1" || report.subtype === "pauseAfterPeriod2" || report.subtype === "pauseAfterOvertimePeriod1" || report.subtype === "pauseAfterOvertimePeriod2") {
                    return <PauseStartComponent report={report} participants={participants} getElapsed={this.getElapsed()} txt="Paus" />;
                }
            }
            case "throwIn": {
                return null;
            }
            case "stoppageTimeAnnounced": {
                return null;
            }
            default: {
                if (this.props.allEvents) {
                    return <FreeKickEventComponent report={report} participants={participants} getElapsed={this.getElapsed()} />;
                }
                else {
                    return null;
                }
            }
        }
    }

    render() {
        return (<>
            {this.getEventForDisplay()}
        </>
        );
    }
}