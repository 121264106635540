import React, { Component } from "react";

export default class InjuryInjuryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            report: this.props.report,
            participants: this.props.participants
        }
    }

    render() {
        return (<li className="report-items-list-item">
            <div className="report-item report-item-injury report-item-injury report-item-football">
                <div className="report-item-type">
                    <div className="report-item-type-icon">
                        <img className="report-icon" src="https://assets.vglive.no/icons/report-items/injury.svg" />
                    </div>
                </div>
                <div className="report-item-body">
                    <div className="report-item-content">Skada: {this.state.participants.team}</div>
                </div>
                <div className="report-item-time">{this.props.getElapsed}</div>
            </div>
        </li>
        );
    }
}