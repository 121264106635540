import React, { useEffect } from "react";
import _ from "lodash";
import teamBlankLogo from "../../images/team-blank-logo.svg";
import dayjs from "dayjs";
import config from "../../config";
export default function EventScoresGenerator(props) {
  const eventsArray = props.events.events;
  const tournaments = props.events.tournaments;
  let eventScoreGenerator = eventsArray.map((pastEvent, index) => {
    if (pastEvent.results !== null && pastEvent.results !== "undefined") {
      var participants = pastEvent.participantIds;
      var results = pastEvent.results;
      var eventDate = dayjs(pastEvent.startDate)
        .locale("sv")
        .format("DD MMMM YYYY");
      if (tournaments !== null && tournaments !== "undefined") {
        var tournamentName = tournaments[pastEvent.tournament.id].name;
      }
      var matchParticipants = props.events.participants;

      var teamOneLogoObject = matchParticipants[participants[0]].images;
      var teamOneLogo = teamOneLogoObject.hasOwnProperty("clubLogo")
        ? teamOneLogoObject.clubLogo.url + "?rule=clip-32x32"
        : teamBlankLogo;
      var teamTwoLogoObject = matchParticipants[participants[1]].images;
      var teamTwoLogo = teamTwoLogoObject.hasOwnProperty("clubLogo")
        ? teamTwoLogoObject.clubLogo.url + "?rule=clip-32x32"
        : teamBlankLogo;

      var text = null;
      if (
        index === 0 &&
        props.showHeader &&
        !_.isEmpty(props.events.participantSearchId)
      ) {
        text = matchParticipants[props.events.participantSearchId].name;
      }
      var eventsHeader = null;
      if (!_.isEmpty(text)) {
        eventsHeader = <div className="match-stats-heading">{text}</div>;
      }
      if (
        !_.isEmpty(
          matchParticipants[participants[0]] &&
            matchParticipants[participants[1]]
        )
      ) {
        return (
          <div className="mal-past-events" key={"eventscore" + index}>
            {eventsHeader}
            <div className="match-stats-content">
              <div className="match-stats-info">
                <div className="league">
                  <span className="icon icon-dot icon-dot__blue"></span>
                  {tournamentName}
                </div>
                <div className="time">{eventDate}</div>
              </div>
            </div>
            <div className="teaser-match-heading">
              <div
                className={
                  "team team-left" +
                  (results[participants[0]].runningScore >
                  results[participants[1]].runningScore
                    ? " font-weight-bold"
                    : "")
                }
              >
                <div className="team-name">
                  {matchParticipants[participants[0]].name}
                </div>
                <img
                  src={teamOneLogo}
                  className="team-logo team-logo__right"
                  alt=""
                ></img>
              </div>
              <div className="score">
                {results[participants[0]].runningScore} -{" "}
                {results[participants[1]].runningScore}
              </div>
              <div
                className={
                  "team" +
                  (results[participants[0]].runningScore <
                  results[participants[1]].runningScore
                    ? " font-weight-bold"
                    : "")
                }
              >
                <img
                  src={teamTwoLogo}
                  className="team-logo team-logo__left"
                  alt=""
                ></img>
                <div className="team-name">
                  {matchParticipants[participants[1]].name}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  });
  return eventScoreGenerator;
}
