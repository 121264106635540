import React, { Component } from "react";
import config from "../config";
import { Object } from "core-js";
import "../styles/components/_stats.scss";
import LoaderComponent from "./LoaderComponent";

export default class StatisticsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventStats: [],
            participants: null,
            loader: true,
            sport: null
        };
        this.state = {
            ...this.state,
            ...this.props
        };
    }

    componentWillMount() {
        this.fetchStatistics(this.state.article);
    }

    fetchStatistics = (article) => {
        var eventId = this.props.eventId;
        fetch(config.schibstedURL + "/ab/statistics/event/" + eventId, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(response => response.json())
            .then(jsondata => {
                this.setState({
                    eventStats: jsondata.eventStatistics,
                    participants: jsondata.participants,
                    loader: false,
                    sport: jsondata.event.sport
                })
            }).catch(function (error) {
                console.log("error while fetching events")
            });
    }

    restructureStatistics = () => {

        if (this.state.participants !== null) {
            var Participants = this.state.participants;
            var participantKeys = Object.keys(Participants);
            var participantOne = participantKeys[0];
            var participantTwo = participantKeys[1];
        }
        var eventStatistics = this.state.eventStats;
        var statsObject = {};
        for (var i = 0; i < eventStatistics.length; i++) {
            var eventStatisticsKey = eventStatistics[i].type;
            if (statsObject.hasOwnProperty(eventStatisticsKey) == false) {
                statsObject[eventStatisticsKey] = {}
            }

            if (eventStatistics[i].teamId == participantOne) {
                statsObject[eventStatisticsKey][participantOne] = eventStatistics[i].value;
            } else if (eventStatistics[i].teamId == participantTwo) {
                statsObject[eventStatisticsKey][participantTwo] = eventStatistics[i].value;
            }
        }
        return this.statsFunction(statsObject, participantOne, participantTwo);
    }

    statsFunction = (statsobject, participantOne, participantTwo) => {
        var statsKeys = Object.keys(statsobject);
        let statsFunction = statsKeys.map(
            (statKey, index) => {
                return (
                    <div key={"stats-" + index} className="stats-details">
                        <div className="stats-key">
                            {this.state.sport === "football" ? config.mapStatsFootball[statKey] : config.mapStatsHockey[statKey]}
                        </div>

                        <div className="stats-graph" key={index}>
                            <div className="stats-score">
                                {statsobject[statKey][participantOne]}
                            </div>
                            <div className="stats-progress-bar-div">
                                <div className="stats-progress-bar">
                                    <div className="stats-progress-bar-left">
                                        <progress className="progress-left" value={statsobject[statKey][participantOne]}
                                            max={statsobject[statKey][participantOne] + statsobject[statKey][participantTwo]}>
                                        </progress>
                                    </div>
                                    <div className="stats-progress-bar-right">
                                        <progress className="progress-right" value={statsobject[statKey][participantTwo]}
                                            max={statsobject[statKey][participantOne] + statsobject[statKey][participantTwo]}>

                                        </progress>
                                    </div>
                                </div>
                            </div>
                            <div className="stats-score">
                                {statsobject[statKey][participantTwo]}
                            </div>
                        </div>
                    </div>
                )
            }
        )
        return statsFunction;
    }

    generateNotFoundMsg = () => {
        return (
            <div className="notfound-msg">
                <h2>{config.notStartedMsg}</h2>
            </div>
        )
    }

    render() {
        let participants = this.state.participants;
        if (this.state.loader) {
            return (
                <LoaderComponent />
            )
        } else {
            if (typeof participants !== "undefined" && participants !== null) {
                var statsDiv = this.restructureStatistics();
                if (statsDiv.length === 0) {
                    statsDiv = this.generateNotFoundMsg();
                }
                var participantKeys = Object.keys(participants);
                var participantOne = participantKeys[0];
                var participantTwo = participantKeys[1];
                return (
                    <>
                        <div className="team-names">
                            <div className="team-name">
                                <span> {participants[participantOne].name} </span>
                            </div>
                            <div className="team-name">
                                <span> {participants[participantTwo].name}</span>
                            </div>
                        </div>
                        <div className="stats">
                            {statsDiv}
                        </div>
                    </>
                )
            }
            else {
                return null;
            }
        }
    }
}